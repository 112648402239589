import { Injectable } from '@angular/core';
import {BaseProviderService} from './base-provider.service';
import {ILibFormService, ILibTableService} from '../@lib/services/contracts.services';
import {ReportExtractMovementFilter, ReportExtractMovementSearch} from '../models/filters.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FilterPagination, PaginatedList} from '../@lib/models/commons.models';
import { ReportExtractMovement } from '../models/report-extract-movement.model';

@Injectable({
  providedIn: 'root'
})
export class ReportExtractMovementService extends BaseProviderService implements ILibTableService<ReportExtractMovement, ReportExtractMovementFilter, ReportExtractMovementSearch>, ILibFormService<ReportExtractMovement> {
  protected module = 'report';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): ReportExtractMovement {
    return new ReportExtractMovement();
  }

  get(id: string): Observable<ReportExtractMovement> {
    return this.http.get<ReportExtractMovement>(this.getUrl(this.module, [id]))
      .pipe(map(x => new ReportExtractMovement(x)));
  }

  list(): Observable<any> {
    return this.http.get(this.getUrl(this.module, ['all']))
      .pipe(map(d => new PaginatedList<ReportExtractMovement>(ReportExtractMovement, d)));
  }

  filter(filter: FilterPagination<ReportExtractMovementFilter, ReportExtractMovementSearch>): Observable<PaginatedList<ReportExtractMovement>> {
    filter.take = 0;
    return this.http.post<PaginatedList<ReportExtractMovement>>(this.getUrl(this.module, ['extract-movement']), filter)
      .pipe(map(d => new PaginatedList<ReportExtractMovement>(ReportExtractMovement, d)));
  }

  update(model: ReportExtractMovement): Observable<ReportExtractMovement> {
    return this.http.patch<ReportExtractMovement>(this.getUrl(this.module, [model.id]), model).pipe(map(x => new ReportExtractMovement(x)));
  }

  save(model: ReportExtractMovement): Observable<ReportExtractMovement> {
    delete model.id;
    return this.http.post<ReportExtractMovement>(this.getUrl(this.module), model).pipe(map(x => new ReportExtractMovement(x)));
  }

  delete(id: number): Observable<ReportExtractMovement> {
    return this.http.delete<ReportExtractMovement>(this.getUrl(this.module, [id]))
      .pipe(map(x => new ReportExtractMovement(x)));
  }

}
