import { Auditable } from '../@lib/models/commons.models';
import { IForm, IModel } from '../@lib/models/contracts.models';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Places } from './places.model';
import { PlaceType } from './place-type.model';
import { Account } from './account.model';

export class UserPlaces extends Places implements IModel, IForm {

  type: string;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.type = other.type;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {


    }
    return this.form;
  }

  updateForm(other) {
    this.form.patchValue(other);
  }
}
