import { Injectable } from '@angular/core';
import { BaseProviderService } from './base-provider.service';
import { ILibFormService, ILibTableService } from '../@lib/services/contracts.services';
import { SideMenuFilter, SideMenuSearch } from '../models/filters.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterPagination, PaginatedList } from '../@lib/models/commons.models';
import { SideMenu } from '../models/side-menu.model';
import { ROUTES } from '../shared/layout/sidebar/sidebar-items';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseProviderService
  implements ILibTableService<SideMenu, SideMenuFilter, SideMenuSearch>, ILibFormService<SideMenu> {
  protected module = 'sidemenu';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): SideMenu {
    return new SideMenu();
  }

  get(id: string): Observable<any> {
    return this.http.get<SideMenu>(this.getUrl(this.module, [id]))
      .pipe(map(d => new PaginatedList<SideMenu>(SideMenu, d)));
  }

  list(): Observable<any> {
    return this.http.get(this.getUrl(this.module, ['all']))
      .pipe(map(d => new PaginatedList<SideMenu>(SideMenu, d)));
  }

  filter(filter: FilterPagination<SideMenuFilter, SideMenuSearch>): Observable<PaginatedList<SideMenu>> {
    return this.http.post<PaginatedList<SideMenu>>(this.getUrl(this.module, ['filter']), filter)
      .pipe(map(d => new PaginatedList<SideMenu>(SideMenu, d)));
  }

  update(model: SideMenu): Observable<SideMenu> {
    return this.http.patch<SideMenu>(this.getUrl(this.module, [model]), model).pipe(map(x => new SideMenu(x)));
  }

  save(model: SideMenu): Observable<SideMenu> {
    return this.http.post<SideMenu>(this.getUrl(this.module), model).pipe(map(x => new SideMenu(x)));
  }

  delete(id: number): Observable<SideMenu> {
    return this.http.delete<SideMenu>(this.getUrl(this.module, [id]))
      .pipe(map(x => new SideMenu(x)));
  }

}
