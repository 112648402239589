import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-nfe-xml',
  templateUrl: './modal-nfe-xml.component.html',
  styleUrls: ['./modal-nfe-xml.component.scss']
})
export class ModalNfeXmlComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  resultData = this.data;
  ngOnInit(): void { }

}
