import {FormControl, FormGroup, Validators} from '@angular/forms';

export class Address {
  id: string;
  active: boolean;
  name: string;
  address: string;
  addressComplement: string;
  city: string;
  district: string;
  state: string;
  zipCode: string;
  country: string;

  form: FormGroup;

  constructor(other?: any) {
    if (other) {
      this.id = other.id;
      this.name = other.name;
      this.address = other.address;
      this.district = other.district
      this.addressComplement = other.addressComplement;
      this.city = other.city;
      this.state = other.state;
      this.zipCode = other.zipCode || other.code;
      this.country = other.country;

    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        name: new FormControl(this.name),
        address: new FormControl(this.address, [Validators.required]),
        district: new FormControl(this.district),
        addressComplement: new FormControl(this.addressComplement),
        city: new FormControl(this.city, [Validators.required]),
        state: new FormControl(this.state, [Validators.required]),
        zipCode: new FormControl(this.zipCode, [Validators.required]),
        country: new FormControl(this.country, [Validators.required]),
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}
