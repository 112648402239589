import {Auditable} from '../@lib/models/commons.models';
import {IForm, IModel} from '../@lib/models/contracts.models';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class Product extends Auditable implements IModel, IForm {

  id: string;
  name: string;
  created_id: string;
  created_at: Date;
  updated_at: Date;
  created_by: string;
  update_id: string;
  update_by: string;
  active: boolean;

  form: FormGroup;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.id = other.id;
      this.name = other.name;
      this.created_at = other.created_at;
      this.updated_at = other.updated_at;
      this.created_id = other.created_id;
      this.created_by = other.created_by;
      this.update_id = other.update_id;
      this.update_by = other.update_by;
      this.active = other.active;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        id: new FormControl(this.id),
        name: new FormControl(this.name, [Validators.required]),
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}

