import { Injectable } from '@angular/core';
import { User, UserPermissionFlat } from '../models/user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseProviderService } from './base-provider.service';
import { map } from 'rxjs/operators';
import { ILibFormService, ILibListService, ILibTableService } from '../@lib/services/contracts.services';
import { UserFilter, UserSearch, UserTypeMovementTypeFilter, UserTypeMovementTypeSearch } from '../models/filters.model';
import { FilterPagination, PaginatedList } from '../@lib/models/commons.models';
import { UserPlaces } from '../models/user-places.model';

@Injectable({
  providedIn: 'root'
})
// tslint:disable-next-line:max-line-length
export class UserService extends BaseProviderService implements ILibTableService<User, UserFilter, UserSearch>, ILibFormService<User>, ILibListService<User>{

  module = 'users';
  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): User {
    return new User();
  }

  get(id: string): Observable<User> {
    return this.http.get<User>(this.getUrl(this.module, ['byid', id]))
      .pipe(map(x => new User(x)));
  }

  getPaginated(id: string): Observable<any> {
    return this.http.get<PaginatedList<UserPermissionFlat>>(this.getUrl(this.module, ['permissions', id]))
      .pipe(map(d => new PaginatedList<UserPermissionFlat>(UserPermissionFlat, d)));
  }

  list(): Observable<any> {
    return this.http.get(this.getUrl(this.module, ['all']))
      .pipe(map(d => new PaginatedList<User>(User, d)));
  }

  filter(filter: FilterPagination<UserFilter, UserSearch>): Observable<PaginatedList<User>> {
    if (filter.search.places === null || filter.search.places as any === '') {
      delete filter.search.places;
    }
    return this.http.post<PaginatedList<User>>(this.getUrl(this.module, ['filter']), filter)
      .pipe(map(d => new PaginatedList<User>(User, d)));
  }

  getPermission(): Observable<User> {
    return this.http.get<User>(this.getUrl(this.module, ['permission']))
      .pipe(map(x => new User(x)));
  }

  update(model: User): Observable<User> {
    return this.http.put<User>(this.getUrl(this.module, ['update-by-id']), model).pipe(map(x => new User(x)));
  }

  updateStatus(userId: string, status: string): Observable<string> {
    return this.http.patch<string>(this.getUrl(this.module, ['status', userId, status]), null);
  }

  updatePhoto(userId: string, photoURL: string): Observable<string> {
    return this.http.patch<string>(this.getUrl(this.module, ['photo', userId]), { photoURL });
  }

  updateUserType(userId: string, userTypeId: string): Observable<string> {
    return this.http.put<string>(this.getUrl(this.module, ['userType', 'update', userId, userTypeId]), null);
  }

  save(model: User): Observable<User> {
    return this.http.post<User>(this.getUrl(this.module), model).pipe(map(x => new User(x)));
  }

  delete(id: number): Observable<User> {
    return this.http.delete<User>(this.getUrl(this.module, [id]))
      .pipe(map(x => new User(x)));
  }

  getUserPlaces(id: string): Observable<PaginatedList<UserPlaces>> {
    return this.http.get<PaginatedList<UserPlaces>>(this.getUrl(this.module, [id, 'places']))
      .pipe(map(x => new PaginatedList<UserPlaces>(UserPlaces, x)));
  }

}

