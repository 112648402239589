import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalNfeXmlComponent } from './modal-nfe-xml/modal-nfe-xml.component';
import { MaterialModule } from '../shared/material.module';
import { TypeLocalModalComponent } from './modals/type-local-modal/type-local-modal.component';
import { TreeCheckboxComponent } from './tree-checkbox/tree-checkbox.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { LookupComponent } from './lookup/lookup.component';
import { SharedModule } from '../shared/shared.module';
import { LookupMovementsPlacesAssignedComponent } from './lookup-movements-places-assigned/lookup-movements-places-assigned.component';
import { ExportComponent } from './export/export.component';



@NgModule({
  declarations: [
    ModalNfeXmlComponent,
    TypeLocalModalComponent,
    TreeCheckboxComponent,
    LoadingScreenComponent,
    LookupComponent,
    LookupMovementsPlacesAssignedComponent,
    ExportComponent
  ],
  exports: [
    TreeCheckboxComponent,
    LoadingScreenComponent,
    LookupComponent,
    LookupMovementsPlacesAssignedComponent,
    ExportComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
