import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[enterToTab]',
})
export class EnterToTabDirective {
    @HostListener('keypress', ['$event']) onKeyPress(keyboardEvent: KeyboardEvent) {

        const target = keyboardEvent.target as
            | HTMLInputElement
            | HTMLTextAreaElement
            | null;

        if (!target) { return; }

        if (keyboardEvent && keyboardEvent.key === 'Enter') {
            const currentIndex = parseInt(target.id.replace('mat-input-', ''), 10);
            const nextElement = document.getElementById(`mat-input-${currentIndex + 1}`);
            nextElement.focus();
            keyboardEvent.preventDefault();
        }
    }

    constructor(private el: ElementRef) { }
}
