import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFilter, IModel, ISearch} from '../../@lib/models/contracts.models';
import {Required} from '../../@lib/decorator/required.decorator';
import {ILibLookupService} from '../../@lib/services/contracts.services';
import {FilterPagination} from '../../@lib/models/commons.models';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {PlacesService} from '../../service/places.service';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-lookup-movements-places-assigned',
  templateUrl: './lookup-movements-places-assigned.component.html',
  styleUrls: ['./lookup-movements-places-assigned.component.scss']
})
export class LookupMovementsPlacesAssignedComponent<TModel extends IModel, TFilter extends IFilter, TSearch extends ISearch> implements OnInit {

  @Input() @Required service: ILibLookupService<TModel, TFilter, TSearch>;
  @Input() @Required fieldName: string;
  @Input() @Required title: string;

  @Input() set clearCamp(clear: boolean) {
    if (clear){
      this.myControl.setValue(null);
    }
  }
  @Input() userPlacesAssigned = false;
  @Input() activeReadonly = false;
  @Input() showOptionAll = false;
  @Input() isServerSide = false;
  @Input() msgInfo = null;
  @Input() isValid = false;
  @Input() alertError = null;
  @Input() filterPagination: FilterPagination<TFilter, TSearch>;
  @Input() id = ''; // Id para iniciar selecionado
  @Input() autoSelectById = false; // Para já invocar o output de seleção

  @Output() optionSelected: EventEmitter<TModel> = new EventEmitter();

  selectedObj = null;

  myControl = new FormControl();
  options: any[] = [];
  filteredOptions: Observable<string[]>;
  loaders = false;

  constructor(public placesServices: PlacesService){}

  ngOnInit(): void {
    // Carregar se foi passado os parâmetros
    if (this.id !== '' && this.id !== undefined && this.id !== null) {
      this.placesServices.get(this.id).subscribe(
        data => {
          if (data) {
            if (this.autoSelectById === false) {
              this.myControl.setValue(data[this.fieldName]);
            } else {
              this.onChangeSelectOption(data);
            }
          }
        }
      );
    }

    if (this.showOptionAll !== false) {
      this.showOptionAll  = true;
    }

    // Se a busca é front-side
    if (this.isServerSide === false) {
      this.service.list().subscribe(
        data => {
          this.options = data.data;

          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterFrontSide(value)),
          );
        },
        (error) => {
          console.log(error);

        }
      );
    }
  }

  private _filterFrontSide(value: string): string[] {
    let filterValue;

    if (value[this.fieldName] !== undefined) {
      filterValue = value[this.fieldName].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } else {
      filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    return this.options.filter(option => option[this.fieldName].toLowerCase()
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filterValue));
  }
  private _getServerSide(value: string) {
    try {
      const types = Object.keys(this.filterPagination);
      if (types.includes('filters')) {

      }

      if (types.includes('search')) {
        // Retorna a lista de nomes dos campos de pesquisa
        const keys = Object.keys(this.filterPagination.search);
        // Retorna a lista de valores dos campos de pesquisa
        const values = Object.values(this.filterPagination.search);

        // Procura qual campo está preenchido
        for (let i = 0; i < values.length; i++) {
          if (values[i] !== undefined) {
            // Preenche o valor no campo da pesquisa
            this.filterPagination.search[keys[i]] = value;
          }
        }
      }

    } catch (error) {
      throw new Error(`Não foi enviado nenhuma propriedade de filter ou search`);
    }

    this.loaders = true;
    this.service
      .filter(this.filterPagination)
      .subscribe(
        (data) => {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(() => data.data as []),
          );
          this.loaders = false;
        },
        (error) => {
          console.log(error);
          this.loaders = false;
        }
      );


  }

  onChangeSelectOption(value) {
    this.selectedObj = value;
    this.myControl.setValue(value[this.fieldName]);
    this.optionSelected.emit(this.selectedObj);
  }

  keyUp($event: KeyboardEvent, value: string) {
    if ($event.code === 'Enter' && this.isServerSide !== false) {
      this._getServerSide(value);
    } else if (this.isServerSide !== false) {
      this.filteredOptions = new Observable();
    }
  }
}
