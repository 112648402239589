import { IModel } from '../@lib/models/contracts.models';

export class SideMenu implements IModel {
  id: string;
  path: string;
  title: string;
  moduleName: string;
  icon: string;
  class: string;
  groupTitle: boolean;
  submenu: SideMenu[];

  constructor(other?: any) {
    if (other) {
      this.id = other.id;
      this.path = other.path;
      this.title = other.title;
      this.moduleName = other.moduleName;
      this.icon = other.icon;
      this.class = other.class;
      this.groupTitle = other.groupTitle;
      this.submenu = new Array<SideMenu>();
      if (other.submenu != null && other.submenu instanceof Array) {
        other.submenu.forEach((value) => this.submenu.push(new SideMenu(value)));
      }
    }
  }
}
