import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Document } from './document.model';
import { AccountType } from '../enum/account-type.enum';
import { RestrictionType } from '../enum/restriction-type.enum';
import { Address } from './address.model';
import { Product } from './product.model';
import { AccountContacts } from './account-contacts.model';
import * as moment from 'moment';

export class Account {
  id: string;
  created_id: string;
  created_at: Date;
  updated_at: Date;
  created_by: string;
  update_id: string;
  update_by: string;
  active: boolean;
  tradingName: string;
  companyName: string;
  type: AccountType;
  restrictionCondition: string;
  restrictionType: RestrictionType;
  documentId: string;
  document: Document;
  addressId: string;
  address: Address;
  productIds: Array<Product> = [];
  accountContacts: Array<AccountContacts> = [];
  isPayment: number;
  codeBank: string;
  accountBank: number;
  agencyBank: number;
  dacBank: number;
  interestAfterDue: number;
  discountValue: number;
  discountRate: number;
  applyDiscount: number;
  dayDue: number;
  restrictionObs: string;
  exceptionObs: string;
  exceptionDateDue: string;
  form: FormGroup;

  constructor(other?: any) {
    if (other) {
      this.id = other.id;
      this.created_at = other.created_at;
      this.updated_at = other.updated_at;
      this.created_id = other.created_id;
      this.created_by = other.created_by;
      this.update_id = other.update_id;
      this.update_by = other.update_by;
      this.active = other.active;
      this.tradingName = other.tradingName;
      this.companyName = other.companyName;
      this.isPayment = other.isPayment;
      this.type = other.type || AccountType.HB;
      this.restrictionCondition = other.restrictionCondition;
      this.restrictionType = other.restrictionType || '';
      this.address = new Address(other.address);
      this.addressId = this.address ? this.address.id : '';
      this.document = new Document(other.document);
      this.documentId = (this.document) ? this.document.id : '';
      this.productIds = new Array<Product>();
      if (other.products != null && other.products instanceof Array) {
        other.products.forEach((value) => this.productIds.push(new Product(value)));
      }
      this.accountContacts = new Array<AccountContacts>();
      if (other.accountContacts != null && other.accountContacts instanceof Array) {
        other.accountContacts.forEach((value) => this.accountContacts.push(new AccountContacts(value)));
      }
      this.codeBank = other.codeBank;
      this.accountBank = other.accountBank;
      this.agencyBank = other.agencyBank;
      this.dacBank = other.dacBank;
      this.interestAfterDue = other.interestAfterDue;
      this.discountValue = other.discountValue;
      this.discountRate = other.discountRate;
      this.applyDiscount = parseFloat(other.applyDiscount);
      this.dayDue = other.dayDue;
      this.restrictionObs = other.restrictionObs;
      this.exceptionObs = other.exceptionObs;
      this.exceptionDateDue = moment(other.exceptionDateDue).format('YYYY-MM-DD');
    } else {
      this.address = new Address();
      this.document = new Document();
      this.interestAfterDue = 0;
      this.applyDiscount = 0;
      this.discountValue = 0;
      this.discountRate = 0;
      this.dayDue = 22;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      const productIds = [];
      this.productIds.forEach((value) => productIds.push(value.asForm));

      const accountContacts = [];
      this.accountContacts.forEach((value) => accountContacts.push(value.asForm));

      this.form = new FormGroup({
        id: new FormControl(this.id),
        tradingName: new FormControl(this.tradingName, [Validators.required]),
        companyName: new FormControl(this.companyName, [Validators.required]),
        isPayment: new FormControl(this.isPayment, [Validators.required]),
        type: new FormControl(this.type, [Validators.required]),
        restrictionCondition: new FormControl('0'),
        restrictionType: new FormControl(this.restrictionType),
        restrictionObs: new FormControl(this.restrictionObs),
        exceptionObs: new FormControl(this.exceptionObs),
        exceptionDateDue: new FormControl(this.exceptionDateDue),
        document: this.document.asForm,
        documentId: new FormControl(this.documentId),
        address: this.address.asForm,
        accountContacts: new FormArray(accountContacts),
        productIds: new FormArray(productIds),
        addressId: new FormControl(this.addressId),
        codeBank: new FormControl(this.codeBank),
        accountBank: new FormControl(this.accountBank),
        agencyBank: new FormControl(this.agencyBank),
        dacBank: new FormControl(this.dacBank),
        interestAfterDue: new FormControl(this.interestAfterDue),
        discountValue: new FormControl(this.discountValue),
        discountRate: new FormControl(this.discountRate),
        applyDiscount: new FormControl(this.applyDiscount),
        dayDue: new FormControl(this.dayDue),
      });
    }
    return this.form;
  }

  get asAccountContactFormArray(): FormArray {
    return this.form.get('accountContacts') as FormArray;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}


