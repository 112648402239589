import { PLACETYPE } from 'src/app/config/config';
import { DateUtils } from 'src/app/shared/utils';
import { IModel } from '../@lib/models/contracts.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Places } from './places.model';
import { TypeRtis } from './typeRtis.model';
import { Account } from './account.model';

export class Timetrend implements IModel {
  updated_at: Date;
  date: string;
  initialBalance: number;
  retrieve: number;
  back: number;
  return: number;
  transfer: number;
  transfer_state: number;
  withdrawal: number;
  delivery: number;
  audit: number;
  regress: number;
  remittance: number;
  finalBalance: number;
  accountId: string;
  account: Account;
  rtiTypeId: string;
  rtiType: TypeRtis;
  cleanQuantity: number;
  dirtyQuantity: number;
  brokenQuantity: number;
  newQuantity: number;
  productQuantity: number;
  transitQuantity: number;
  place_id: string;
  place: Places;
  totalMovement: number;

  initialBalanceStore: number;
  finalBalanceStore: number;

  constructor(other?: any) {
    if (other) {
      this.updated_at = DateUtils.toDate(other.updated_at);
      this.date = other.date;
      this.initialBalance = other.initialBalance;
      this.retrieve = other.retrieve;
      this.back = other.back;
      this.return = other.return;
      this.remittance = other.remittance;
      this.regress = other.regress;
      this.transfer = other.transfer;
      this.transfer_state = other.transfer_state;
      this.withdrawal = other.withdrawal;
      this.delivery = other.delivery;
      this.audit = other.audit;
      this.finalBalance = other.finalBalance;
      this.accountId = other.accountId;
      this.rtiTypeId = other.rtiTypeId;
      this.rtiType = other.rtiType;
      this.cleanQuantity = other.cleanQuantity;
      this.dirtyQuantity = other.dirtyQuantity;
      this.brokenQuantity = other.brokenQuantity;
      this.newQuantity = other.newQuantity;
      this.productQuantity = other.productQuantity;
      this.transitQuantity = other.transitQuantity;
      this.account = other.place_id;
      this.place = other.place;
      this.account = other.account;
      this.totalMovement = other.totalMovement;
      this.initialBalanceStore = other.initialBalanceStore;
      this.finalBalanceStore = other.finalBalanceStore;
    }
  }

  get report() {
    if (this.place.placeTypeId !== PLACETYPE.loja.id) {
      return {
        Data: DateUtils.toBRDateString(this.date),
        'Tipo de Conta': this.account.type,
        Conta: this.account.tradingName,
        Local: this.place?.tradingName,
        RTI: this.rtiType?.name,
        'Saldo Total': this.finalBalance,
        'Saldo Limpa': this.cleanQuantity,
        'Saldo Suja': this.dirtyQuantity,
        'Saldo Produto': this.productQuantity,
        'Saldo Avariada': this.brokenQuantity,
        'Saldo Nova': this.newQuantity,
        'Saldo Trânsito': this.transitQuantity,
        'Movimento Total': this.totalMovement,
        'Movimento Retiradas': this.withdrawal,
        'Movimento Devolução': this.back,
        'Movimento Entrega': this.delivery,
        'Movimento Devolução de Entrega': this.retrieve,
        'Movimento Transferência de Local': this.transfer,
        'Movimento Retorno': this.return,
        'Movimento Auditoria': this.audit,
      };
    } else {
      return {
        Data: DateUtils.toBRDateString(this.date),
        'Tipo de Conta': this.account.type,
        Conta: this.account.tradingName,
        Local: this.place?.tradingName,
        RTI: this.rtiType?.name,
        'Saldo Total': this.finalBalanceStore,
        'Movimento Total': this.totalMovement,
        'Movimento Remessa': this.regress,
        'Movimento Regresso': this.remittance
      };
    }

  }
}

