import { Auditable } from '../@lib/models/commons.models';
import { IForm, IModel } from '../@lib/models/contracts.models';
import { PlaceType } from './place-type.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Account } from './account.model';
import { Address } from './address.model';
import { Contact } from './contact.model';
import { Document } from './document.model';
import * as moment from 'moment';

export class Places extends Auditable implements IModel, IForm {
  id: string;
  created_at: Date;
  updated_at: Date;
  created_id: string;
  created_by: string;
  update_id: string;
  update_by: string;
  active: boolean;
  tradingName: string;
  companyName: string;
  description: string;
  placeTypeId: string;
  accountId: string;
  defaultContactId: string;
  placeType: PlaceType;
  account: Account;
  defaultContact: Contact;
  addressId: string;
  address: Address;
  documentId: string;
  document: Document;
  form: FormGroup;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.id = other.id;
      this.created_at = other.created_at;
      this.updated_at = other.updated_at;
      this.created_id = other.created_id;
      this.created_by = other.created_by;
      this.update_id = other.update_id;
      this.update_by = other.update_by;
      this.active = other.active;
      this.tradingName = other.tradingName;
      this.companyName = other.companyName;
      this.description = other.description;
      this.placeTypeId = other.placeTypeId;
      this.accountId = other.accountId;
      this.defaultContactId = other.defaultContactId;
      this.placeType = other.placeType;
      this.account = other.account;
      this.defaultContact = other.defaultContact;
      this.addressId = (this.address) ? this.address.id : '';
      this.address = new Address(other.address);
      this.documentId = (this.document) ? this.document.id : '';
      this.document = new Document(other.document);
    } else {
      this.address = new Address();
      this.document = new Document();
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        id: new FormControl(this.id),
        tradingName: new FormControl(this.tradingName, [Validators.required]),
        companyName: new FormControl(this.companyName, [Validators.required]),
        description: new FormControl(this.description),
        addressId: new FormControl((this.address) ? this.address.id : ''),
        address: this.address.asForm,
        documentId: new FormControl((this.document) ? this.document.id : ''),
        document: this.document.asForm,
        placeTypeId: new FormControl(this.placeTypeId, [Validators.required]),
        accountId: new FormControl(this.accountId, [Validators.required]),
        defaultContactId: new FormControl(this.defaultContactId),
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}
