import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private readonly config: MatSnackBarConfig;

    constructor(private snackBar: MatSnackBar, public dlog: MatDialog, ) {
        this.config = new MatSnackBarConfig();
        this.config.duration = 4500;
    }

    message(message: string) {
        this.open(message, ['alert-message']);
    }

    success(message: string) {
        this.open(message, ['alert-success']);
    }

    error(message: string) {
        this.open(message, ['alert-error']);
    }

    warning(message: string) {
        this.open(message, ['alert-warning']);
    }

    private open(message: string, classes: Array<string>, action: string = '') {
        this.config.panelClass = classes;
        this.snackBar.open(message, action, this.config);
    }

    dialog(title, message): Observable<any> {
        const dialogRef = this.dlog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                title,
                message,
                btnOkText: 'Sim',
                btnCancelText: 'Não'
            }
        });
        return dialogRef.afterClosed();
    }

    /**
     * Dialog para confirmar ação
     * @param title Nome do item que será excluído
     * @returns uma Promise
     */
    dialogConfirm(title): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            Swal.fire({
                title,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve(true);
                } else if (result.isDenied) {
                    Swal.fire('Ação cancelada', '', 'error');
                    reject(false);
                }
            });
        });
    }

    /**
     * Dialog para confirmar ação
     * @param title Nome do item que será excluído
     * @returns uma Promise
     */
     dialogConfirmYorN(title): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            Swal.fire({
                title,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: `Não`,
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve(true);
                } else if (result.isDenied) {
                    resolve(false);
                }
            });
        });
    }

    /**
     * Dialog para confirmar se vai querer alterar o status
     * @param active // Valor do status
     * @param title Nome do item que será excluído
     * @returns uma Promise
     */
    changeStatus(active, title): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            Swal.fire({
                title: `Deseja ${active === true ? 'desativar' : 'ativar'} o <br> ${title}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: `Não`,
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve(true);
                } else if (result.isDenied) {
                    Swal.fire('Ação cancelada', '', 'error');
                    reject(false);
                }
            });
        });
    }
}
