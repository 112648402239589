import {FormControl, FormGroup, Validators} from '@angular/forms';

export class Document {
  id: string;
  document: string;
  documentTypeId: string;

  form: FormGroup;

  constructor(other?: any) {
    if (other) {
      this.id = other.id;
      this.document = other.document;
      this.documentTypeId = other.documentTypeId;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        document: new FormControl(this.document, [Validators.required]),
        documentTypeId: new FormControl(this.documentTypeId, [Validators.required])
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}
