import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../../service/auth.service';
import { Router, RouterState } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/@lib/services/alert.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private router: Router, protected alertService: AlertService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const token = localStorage.getItem('ct');

    this.validateUrl(this.router.routerState.snapshot.url);

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      this.router.navigate(['/authentication/signin']);
    }


    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          return event;
        }
      }, error => {
        if (error === 'No token provided') {
          this.authenticationService.logout();
        } else {
          this.alertService.error(error);
        }

      },
        () => {

        }
      ));
  }

  async validateUrl(url: string): Promise<void> {
    let valid = true;

    try {
      if (url) {
        // Remover a primeira /
        url = url.slice(1);
        const urlArray = url.split('/');

        const found = await this.authenticationService.getPermissionByModuleName(urlArray);

        if (urlArray.includes('authentication') || urlArray.includes('main')) {
          valid = true;
        } else if (urlArray.includes('create')) {
          valid = found.create;
        } else if (urlArray.includes('edit')) {
          valid = found.update;
        } else {
          valid = found.read;
        }
      } else {
        valid = true;
      }

      if (!valid) {
        this.router.navigate(['/authentication/forbidden']);
      };
    } catch (error) {

    }
  }

}
