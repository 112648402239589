export const StatesListBrazil = {
  UF: [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' }

  ]
};

// Icones
export const IconsThemify = [
  { name: 'ti-arrow-up' },
  { name: 'ti-arrow-right' },
  { name: 'ti-arrow-left' },
  { name: 'ti-arrow-down' },
  { name: 'ti-arrows-vertical' },
  { name: 'ti-arrows-horizontal' },
  { name: 'ti-angle-up' },
  { name: 'ti-angle-up' },
  { name: 'ti-angle-left' },
  { name: 'ti-angle-down' },
  { name: 'ti-angle-double-up' },
  { name: 'ti-angle-double-up' },
  { name: 'ti-angle-double-left' },
  { name: 'ti-angle-double-down' },
  { name: 'ti-move' },
  { name: 'ti-fullscreen' },
  { name: 'ti-arrow-top-right' },
  { name: 'ti-arrow-top-left' },
  { name: 'ti-arrow-circle-up' },
  { name: 'ti-arrow-circle-right' },
  { name: 'ti-arrow-circle-left' },
  { name: 'ti-arrow-circle-down' },
  { name: 'ti-arrows-corner' },
  { name: 'ti-split-v' },
  { name: 'ti-split-v-alt' },
  { name: 'ti-split-h' },
  { name: 'ti-hand-point-up' },
  { name: 'ti-hand-point-right' },
  { name: 'ti-hand-point-left' },
  { name: 'ti-hand-point-down' },
  { name: 'ti-back-right' },
  { name: 'ti-back-left' },
  { name: 'ti-exchange-vertical' },
  { name: 'ti-wand' },
  { name: 'ti-save' },
  { name: 'ti-save-alt' },
  { name: 'ti-save-alt' },
  { name: 'ti-direction-alt' },
  { name: 'ti-user' },
  { name: 'ti-link' },
  { name: 'ti-unlink' },
  { name: 'ti-trash' },
  { name: 'ti-target' },
  { name: 'ti-tag' },
  { name: 'ti-desktop' },
  { name: 'ti-tablet' },
  { name: 'ti-mobile' },
  { name: 'ti-email' },
  { name: 'ti-star' },
  { name: 'ti-spray' },
  { name: 'ti-signal' },
  { name: 'ti-shopping-cart' },
  { name: 'ti-shopping-cart-full' },
  { name: 'ti-settings' },
  { name: 'ti-search' },
  { name: 'ti-zoom-in' },
  { name: 'ti-zoom-out' },
  { name: 'ti-cut' },
  { name: 'ti-ruler' },
  { name: 'ti-ruler-alt-2' },
  { name: 'ti-ruler-pencil' },
  { name: 'ti-ruler-alt' },
  { name: 'ti-bookmark' },
  { name: 'ti-bookmark-alt' },
  { name: 'ti-reload' },
  { name: 'ti-plus' },
  { name: 'ti-minus' },
  { name: 'ti-close' },
  { name: 'ti-pin' },
  { name: 'ti-pencil' },
  { name: 'ti-pencil-alt' },
  { name: 'ti-paint-roller' },
  { name: 'ti-paint-bucket' },
  { name: 'ti-na' },
  { name: 'ti-medall' },
  { name: 'ti-medall-alt' },
  { name: 'ti-marker' },
  { name: 'ti-marker-alt' },
  { name: 'ti-layers-alt' },
  { name: 'ti-key' },
  { name: 'ti-image' },
  { name: 'ti-heart' },
  { name: 'ti-heart-broken' },
  { name: 'ti-hand-stop' },
  { name: 'ti-hand-open' },
  { name: 'ti-hand-drag' },
  { name: 'ti-flag-alt' },
  { name: 'ti-flag-alt-2' },
  { name: 'ti-eye' },
  { name: 'ti-import' },
  { name: 'ti-export' },
  { name: 'ti-cup' },
  { name: 'ti-crown' },
  { name: 'ti-comments' },
  { name: 'ti-comment' },
  { name: 'ti-comment-alt' },
  { name: 'ti-thought' },
  { name: 'ti-clip' },
  { name: 'ti-check' },
  { name: 'ti-check-box' },
  { name: 'ti-camera' },
  { name: 'ti-announcement' },
  { name: 'ti-brush' },
  { name: 'ti-brush-alt' },
  { name: 'ti-palette' },
  { name: 'ti-briefcase' },
  { name: 'ti-bolt' },
  { name: 'ti-bolt-alt' },
  { name: 'ti-blackboard' },
  { name: 'ti-bag' },
  { name: 'ti-world' },
  { name: 'ti-wheelchair' },
  { name: 'ti-car' },
  { name: 'ti-truck' },
  { name: 'ti-timer' },
  { name: 'ti-ticket' },
  { name: 'ti-thumb-up' },
  { name: 'ti-thumb-down' },
  { name: 'ti-stats-up' },
  { name: 'ti-stats-down' },
  { name: 'ti-shine' },
  { name: 'ti-shift-right' },
  { name: 'ti-shift-left' },
  { name: 'ti-shift-right-alt' },
  { name: 'ti-shift-left-alt' },
  { name: 'ti-shield' },
  { name: 'ti-notepad' },
  { name: 'ti-server' },
  { name: 'ti-pulse' },
  { name: 'ti-printer' },
  { name: 'ti-power-off' },
  { name: 'ti-plug' },
  { name: 'ti-pie-chart' },
  { name: 'ti-panel' },
  { name: 'ti-package' },
  { name: 'ti-music' },
  { name: 'ti-music-alt' },
  { name: 'ti-mouse' },
  { name: 'ti-mouse-alt' },
  { name: 'ti-money' },
  { name: 'ti-microphone' },
  { name: 'ti-menu' },
  { name: 'ti-menu-alt' },
  { name: 'ti-map' },
  { name: 'ti-map-alt' },
  { name: 'ti-location-pin' },
  { name: 'ti-light-bulb' },
  { name: 'ti-info' },
  { name: 'ti-infinite' },
  { name: 'ti-id-badge' },
  { name: 'ti-hummer' },
  { name: 'ti-home' },
  { name: 'ti-help' },
  { name: 'ti-headphone' },
  { name: 'ti-harddrives' },
  { name: 'ti-harddrive' },
  { name: 'ti-gift' },
  { name: 'ti-game' },
  { name: 'ti-filter' },
  { name: 'ti-files' },
  { name: 'ti-file' },
  { name: 'ti-zip' },
  { name: 'ti-folder' },
  { name: 'ti-envelope' },
  { name: 'ti-dashboard' },
  { name: 'ti-cloud' },
  { name: 'ti-cloud-up' },
  { name: 'ti-cloud-down' },
  { name: 'ti-cloud-down' },
  { name: 'ti-calendar' },
  { name: 'ti-book' },
  { name: 'ti-bell' },
  { name: 'ti-basketball' },
  { name: 'ti-bar-chart' },
  { name: 'ti-bar-chart-alt' },
  { name: 'ti-archive' },
  { name: 'ti-anchor' },
  { name: 'ti-alert' },
  { name: 'ti-alarm-clock' },
  { name: 'ti-agenda' },
  { name: 'ti-write' },
  { name: 'ti-wallet' },
  { name: 'ti-video-clapper' },
  { name: 'ti-video-camera' },
  { name: 'ti-vector' },
  { name: 'ti-support' },
  { name: 'ti-stamp' },
  { name: 'ti-slice' },
  { name: 'ti-shortcode' },
  { name: 'ti-receipt' },
  { name: 'ti-pin2' },
  { name: 'ti-pin-alt' },
  { name: 'ti-pencil-alt2' },
  { name: 'ti-eraser' },
  { name: 'ti-more' },
  { name: 'ti-more-alt' },
  { name: 'ti-microphone-alt' },
  { name: 'ti-magnet' },
  { name: 'ti-line-double' },
  { name: 'ti-line-dotted' },
  { name: 'ti-line-dashed' },
  { name: 'ti-ink-pen' },
  { name: 'ti-info-alt' },
  { name: 'ti-help-alt' },
  { name: 'ti-headphone-alt' },
  { name: 'ti-gallery' },
  { name: 'ti-face-smile' },
  { name: 'ti-face-sad' },
  { name: 'ti-credit-card' },
  { name: 'ti-comments-smiley' },
  { name: 'ti-time' },
  { name: 'ti-share' },
  { name: 'ti-share-alt' },
  { name: 'ti-rocket' },
  { name: 'ti-new-window' },
  { name: 'ti-rss' },
  { name: 'ti-rss-alt' },
  { name: 'ti-control-stop' },
  { name: 'ti-control-shuffle' },
  { name: 'ti-control-play' },
  { name: 'ti-control-pause' },
  { name: 'ti-control-forward' },
  { name: 'ti-control-backward' },
  { name: 'ti-volume' },
  { name: 'ti-control-skip-forward' },
  { name: 'ti-control-skip-backward' },
  { name: 'ti-control-record' },
  { name: 'ti-control-eject' },
  { name: 'ti-paragraph' },
  { name: 'ti-uppercase' },
  { name: 'ti-underline' },
  { name: 'ti-text' },
  { name: 'ti-Italic' },
  { name: 'ti-smallcap' },
  { name: 'ti-list' },
  { name: 'ti-list-ol' },
  { name: 'ti-align-right' },
  { name: 'ti-align-left' },
  { name: 'ti-align-justify' },
  { name: 'ti-align-center' },
  { name: 'ti-quote-right' },
  { name: 'ti-quote-left' },
  { name: 'ti-layout-width-full' },
  { name: 'ti-layout-width-default' },
  { name: 'ti-layout-width-default-alt' },
  { name: 'ti-layout-tab' },
  { name: 'ti-layout-tab-window' },
  { name: 'ti-layout-tab-v' },
  { name: 'ti-layout-tab-min' },
  { name: 'ti-layout-slider' },
  { name: 'ti-layout-slider-alt' },
  { name: 'ti-layout-sidebar-right' },
  { name: 'ti-layout-sidebar-none' },
  { name: 'ti-layout-sidebar-left' },
  { name: 'ti-layout-placeholder' },
  { name: 'ti-layout-menu' },
  { name: 'ti-layout-menu-v' },
  { name: 'ti-layout-menu-separated' },
  { name: 'ti-layout-menu-full' },
  { name: 'ti-layout-media-right' },
  { name: 'ti-layout-media-right' },
  { name: 'ti-layout-media-overlay' },
  { name: 'ti-layout-media-overlay-alt' },
  { name: 'ti-layout-media-overlay-alt-2' },
  { name: 'ti-layout-media-left' },
  { name: 'ti-layout-media-left' },
  { name: 'ti-layout-media-center' },
  { name: 'ti-layout-media-center-alt' },
  { name: 'ti-layout-list-thumb' },
  { name: 'ti-layout-list-thumb-alt' },
  { name: 'ti-layout-list-post' },
  { name: 'ti-layout-list-large-image' },
  { name: 'ti-layout-line-solid' },
  { name: 'ti-layout-grid4' },
  { name: 'ti-layout-grid3' },
  { name: 'ti-layout-grid2' },
  { name: 'ti-layout-grid2-thumb' },
  { name: 'ti-layout-cta-right' },
  { name: 'ti-layout-cta-left' },
  { name: 'ti-layout-cta-center' },
  { name: 'ti-layout-cta-btn-right' },
  { name: 'ti-layout-cta-btn-left' },
  { name: 'ti-layout-column4' },
  { name: 'ti-layout-column3' },
  { name: 'ti-layout-column2' },
  { name: 'ti-layout-column2' },
  { name: 'ti-layout-accordion-merged' },
  { name: 'ti-layout-accordion-list' },
  { name: 'ti-widgetized' },
  { name: 'ti-widget' },
  { name: 'ti-widget-alt' },
  { name: 'ti-view-list' },
  { name: 'ti-view-list-alt' },
  { name: 'ti-view-grid' },
  { name: 'ti-upload' },
  { name: 'ti-download' },
  { name: 'ti-loop' },
  { name: 'ti-layout-sidebar-2' },
  { name: 'ti-layout-grid4-alt' },
  { name: 'ti-layout-grid3-alt' },
  { name: 'ti-layout-grid2-alt' },
  { name: 'ti-layout-column4-alt' },
  { name: 'ti-layout-column3-alt' },
  { name: 'ti-layout-column2-alt' },
  { name: 'ti-flickr' },
  { name: 'ti-flickr-alt' },
  { name: 'ti-instagram' },
  { name: 'ti-google' },
  { name: 'ti-github' },
  { name: 'ti-facebook' },
  { name: 'ti-dropbox' },
  { name: 'ti-dropbox-alt' },
  { name: 'ti-dribbble' },
  { name: 'ti-apple' },
  { name: 'ti-android' },
  { name: 'ti-yahoo' },
  { name: 'ti-trello' },
  { name: 'ti-stack-overflow' },
  { name: 'ti-soundcloud' },
  { name: 'ti-sharethis' },
  { name: 'ti-sharethis-alt' },
  { name: 'ti-reddit' },
  { name: 'ti-microsoft' },
  { name: 'ti-microsoft-alt' },
  { name: 'ti-linux' },
  { name: 'ti-jsfiddle' },
  { name: 'ti-joomla' },
  { name: 'ti-html5' },
  { name: 'ti-css3' },
  { name: 'ti-drupal' },
  { name: 'ti-wordpress' },
  { name: 'ti-tumblr' },
  { name: 'ti-tumblr-alt' },
  { name: 'ti-skype' },
  { name: 'ti-youtube' },
  { name: 'ti-vimeo' },
  { name: 'ti-vimeo-alt' },
  { name: 'ti-twitter' },
  { name: 'ti-twitter-alt' },
  { name: 'ti-linkedin' },
  { name: 'ti-pinterest' },
  { name: 'ti-pinterest-alt' },
];

export const MOVEMENTTYPE = {
  auditoria: {
    id: 'b5d5450d-f3fe-4d98-a3d6-6da8f7726b50',
    title: 'Auditoria'
  },
  devolucao: {
    id: 'ef34dd46-580d-4776-b998-a8d8702adf53',
    title: 'Devolução'
  },
  devolucaoEntrega: {
    id: 'd03358ca-7570-4a1b-b5c7-5e4ffc1acd47',
    title: 'Devolução Entrega'
  },
  entrega: {
    id: '75bc2265-9505-4939-818b-6415b877a7d4',
    title: 'Entrega'
  },
  entregaParcial: {
    id: 'fc60a56c-26f8-47f2-b144-5481250f31be',
    title: 'Entrega Parcial'
  },
  regresso: {
    id: '5825ea34-a333-4822-9a0d-e8f5594b0a11',
    title: 'Regresso'
  },
  retirada: {
    id: '801b2de6-c6e4-4cab-b745-74610178e782',
    title: 'Retirada'
  },
  retorno: {
    id: '3acc6616-2993-4609-bf16-972db0e8a89b',
    title: 'Retorno'
  },
  remessa: {
    id: '449f5918-edc1-4f16-a36a-ce65ec891fcf',
    title: 'Remessa'
  },
  transferencia: {
    id: '71f2436a-183b-4e31-a0db-e3dc80b32de2',
    title: 'Transferência'
  },
  transferenciaEstado: {
    id: 'cf9ab2cd-786a-4fbb-b829-02571ed53298',
    title: 'Transferência Estado'
  }
};

export const MOVEMENTSTEP = {
  agendamento: {
    id: '4248fbde-b052-4c25-8f6c-9f827a1453bd',
    title: 'Agendamento'
  },
  autorizacao: {
    id: '35acff31-1e2e-42ad-bd34-83c994d1c305',
    title: 'Autorização'
  },
  agendamentoRetorno: {
    id: '56beba3c-f482-4826-b3d9-a8af85acf2f7',
    title: 'Agendamento Retornos'
  },
  validacao: {
    id: '6e6554d4-6e32-4647-bc14-c51fb0f960d5',
    title: 'Validação'
  },
  preparacaoCarga: {
    id: '0444ac78-0b6c-4356-84a9-f1586811bcd2',
    title: 'Preparação de Carga'
  },
  transito: {
    id: '3f248c24-25dc-49ee-8fde-e6c3a48188eb',
    title: 'Trânsito'
  },
  registro: {
    id: '8fd66e5e-8c36-4c9f-83f5-0d66f4a96e09',
    title: 'Registro'
  },
  concluido: {
    id: '65d256b5-c732-4bb6-9153-81e0edacae16',
    title: 'Concluído'
  },
};

export const RTISTATUS = {
  nova: {
    id: 'dee33de9-616e-42db-87c6-34412196e53f',
    title: 'nova'
  },
  avariada: {
    id: 'ad20f5a0-9c4d-4d7b-b7ba-5f36c3716ea7',
    title: 'Avariada'
  },
  limpa: {
    id: '94ecad2c-dedc-4e49-94a1-96022933005a',
    title: 'Limpa'
  },
  produto: {
    id: '8137f850-d41e-4b71-9d7c-0db1c8cc3589',
    title: 'Produto'
  },
  suja: {
    id: '150c43f8-2638-4b3f-a438-f89339c6c235',
    title: 'Suja'
  },
  em_transito: {
    id: 'fb8984bb-3c68-4568-a35e-148eebb66c59',
    title: 'Em Trânsito'
  }
};

export const PLACETYPE = {
  hb: {
    id: '464cfbde-ffd3-47cf-9a08-0c086763af4a',
    title: 'HB'
  },
  loja: {
    id: '4ddf2739-2b42-4b3c-abb9-b03f5c5be5d8',
    title: 'Loja'
  },
  produtor: {
    id: 'ff5d78a7-91ce-4d3d-a777-8f989df827f6',
    title: 'Produtor'
  },
  varejista: {
    id: '1f411bb0-fa6a-47be-a836-45c1204f4b7c',
    title: 'Varejista'
  },
  auditoria: {
    id: 'd67108fe-7cc7-4480-abed-1bc7f67c8022',
    title: 'Auditoria'
  }
};


export const PAYMENTSTATUS = {
  pago_parcialmente: {
    id: '6eebb2ae-8c8d-4901-b146-0d899fd6a8d9',
    title: 'Pago Parcialmente'
  },
  emAtraso: {
    id: '76312a26-2e38-4318-a47e-9d716253091b',
    title: 'em Atraso'
  },
  pago: {
    id: '31c6f454-ef96-4944-a268-0ada02cd3024',
    title: 'Pago'
  },
  pendente: {
    id: '520c8584-7bb5-4db6-bdb5-e7e467a1a72e',
    title: 'Pendente'
  },
  cancelado: {
    id: '3e72e8b0-0c41-4e9e-a3fa-4e0e06318761',
    title: 'Cancelado'
  }
};

export const DOCUMENTTYPE = {
  CPF: {
    id: 'edc74798-792b-4cab-9c48-4943f0f4bfc3',
    title: 'CPF',
    mask: '000.000.000-00'
  },
  CNPJ: {
    id: 'd13ba862-2297-445b-a966-23133b05856a',
    title: 'CNPJ',
    mask: '00.000.000/0000-00'
  },
};
export const VALUESTEPMOVEMENT = {
  NEGADO: {
    value: 3,
    msg: 'Existe Tarefa pendente',
  },
  NEUTRO: {
    value: 0,
    msg: 'Ainda não foi realizado as tarefas',
  },
  APROVADO: {
    value: 2,
    msg: 'Tarefas realizadas',
  },
  EMPROGRESSO: {
    value: 1,
    msg: 'Realiando',
  },
};
export const MOVEMENTFILTERNEXTSTEP = {
  NEGADO: {
    value: 3,
  },
  APROVADO: {
    value: 2,
  },
  CONCLUIDO: {
    value: 1,
  },
};
export const MOVEMENTPERMISSIONSTEP = {
  BLOQUEADO: {
    value: 'hide',
  },
  VISUALIZAR: {
    value: 'view',
  },
  EDITAVEL: {
    value: 'edit',
  },
};
