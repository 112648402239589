import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Contact} from './contact.model';

export class Phone {
  id: number;
  number: number;
  ddi: number;
  ddd: number;

  form: FormGroup;
  constructor(other?: any) {
    if (other) {
      this.id = other.id;
      this.number = other.number;
      this.ddi = other.ddi;
      this.ddd = other.ddd;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        id: new FormControl(this.id),
        number: new FormControl(this.number, [Validators.required]),
        ddi: new FormControl(this.ddi, [Validators.required]),
        ddd: new FormControl(this.ddd, [Validators.required])
      });
    }
    return this.form;
  }

  updateForm(other) {
    this.form.patchValue(other);
  }
}
