import { IFilter, ISearch } from '../@lib/models/contracts.models';
import { UserType } from './user-type.model';
import { BankTransition } from './bank-transition.model';
import { ProducerAttendaceInvoices } from './producer-attendace-invoices.model';
import { ProducerAttendaceMovementError } from './producer-attendace-movement-error.model';
import { ProducerAttendaceScheduling } from './producer-attendace-scheduling.model';
import { UserPlacesAssigned } from './user-places-assigned.model';

// Account ===================================================================================================
export class AccountFilter implements IFilter {
  active: boolean;
  isPayment: number;
  placeTypeId = undefined;
  type;
  // legalRepresentative: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.isPayment = other.isPayment;
      this.placeTypeId = other.placeTypeId;
      this.type = other.type;
      //  this.legalRepresentative = other.legalRepresentative;
    }
  }
}

export class AccountSearch implements ISearch {
  tradingName: string;
  companyName: string;

  constructor(other?: any) {
    if (other != null) {
      this.tradingName = other.tradingName;
      this.companyName = other.companyName;
    }
  }
}

// AccountCashFlow ===================================================================================================
export class AccountCashFlowFilter implements IFilter {
  active: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
    }
  }
}

export class AccountCashFlowSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}
// Attendance Producer Movement - Account ===================================================================================================
export class AttendanceProducerMovementAccountFilter implements IFilter {
  active: boolean;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.accountId = other.accountId;
    }
  }
}

export class AttendanceProducerMovementAccountSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Attendance Producer Movement - Place ===================================================================================================
export class AttendanceProducerMovementPlaceFilter implements IFilter {
  active: boolean;
  placeId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
    }
  }
}

export class AttendanceProducerMovementPlaceSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// AttendanceProducer ===================================================================================================
export class AttendanceProducerFilter implements IFilter {
  active: boolean;
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.name = other.name;
    }
  }
}

export class AttendanceProducerSearch implements ISearch {
  name: string;
  bank_code: number;
  agency: number;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.bank_code = other.bank_code;
      this.agency = other.agency;
    }
  }
}

// Bank ===================================================================================================
export class BankFilter implements IFilter {
  active: boolean;
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.name = other.name;
    }
  }
}

export class BankSearch implements ISearch {
  name: string;
  bank_code: number;
  agency: number;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.bank_code = other.bank_code;
      this.agency = other.agency;
    }
  }
}

// Bank Transaction ===================================================================================================
export class BankTransitionFilter implements IFilter {
  active: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
    }
  }
}

export class BankTransitionSearch implements ISearch {
  withdrawName: string;
  myNumber: string;

  constructor(other?: any) {
    if (other != null) {
      this.withdrawName = other.withdrawName;
      this.myNumber = other.myNumber;
    }
  }
}

// Cnab ===================================================================================================
export class CnabFilter implements IFilter {

  constructor(other?: any) {
    if (other != null) {

    }
  }
}

export class CnabSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {

    }
  }
}

// Contacts ===================================================================================================
export class ContactFilter implements IFilter {
  active: boolean;
  legalRepresentative: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.legalRepresentative = other.legalRepresentative;
    }
  }
}

export class ContactSearch implements ISearch {
  name: string;
  email: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.email = other.email;
      this.description = other.description;
    }
  }
}

// Contract ===================================================================================================
export class ContractFilter implements IFilter {
  active: boolean;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.accountId = other.accountId;
    }
  }
}

export class ContractSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {

    }
  }
}

// Document Type ===================================================================================================
export class DocumentTypeFilter implements IFilter {
  active: boolean;
  legalRepresentative: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.legalRepresentative = other.legalRepresentative;
    }
  }
}

export class DocumentTypeSearch implements ISearch {
  name: string;
  email: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.email = other.email;
      this.description = other.description;
    }
  }
}

// File ===================================================================================================
export class FileFilter implements IFilter {
  active: boolean;
  // legalRepresentative: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      //  this.legalRepresentative = other.legalRepresentative;
    }
  }
}

export class FileSearch implements ISearch {
  name: string;
  description: string;
  title: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.description = other.description;
      this.title = other.title;
    }
  }
}

// For Name ===================================================================================================
export class ForNameFilter implements IFilter {
  active: boolean;
  // legalRepresentative: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      //  this.legalRepresentative = other.legalRepresentative;
    }
  }
}

export class ForNameSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// Momements ===================================================================================================
export class MovementsFilter implements IFilter {
  movementTypeIds: Array<string>;
  movementStepIds = [];
  originId: string;
  destinationId: string;
  dateStart: string;
  dateEnd: string;
  accountId: string;
  guide_number: number;
  bothPlaces = false;
  need_recalculate_stock: number;
  constructor(other?: any) {
    if (other != null) {
      this.movementTypeIds = new Array<string>();
      if (other.movementTypeIds != null && other.movementTypeIds instanceof Array) {
        other.movementTypeIds.forEach((value) => { this.movementTypeIds.push(value); });
      }
      this.movementStepIds = other.movementStepIds;
      this.originId = other.originId;
      this.destinationId = other.destinationId;
      this.dateStart = other.dateStart;
      this.dateEnd = other.dateEnd;
      this.accountId = other.accountId;
      this.guide_number = other.guide_number;
      this.bothPlaces = other.bothPlaces;
      this.need_recalculate_stock = other.need_recalculate_stock;
    }
  }
}

export class MovementsSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {

    }
  }
}

// MovementStep ===================================================================================================
export class MovementStepFilter implements IFilter {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

export class MovementStepSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// MovementTypeStep ===================================================================================================
export class MovementTypeStepFilter implements IFilter {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

export class MovementTypeStepSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// Payment Status ===================================================================================================
export class PaymentStatusFilter implements IFilter {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

export class PaymentStatusSearch implements ISearch {
  name: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.description = other.description;
    }
  }
}
// Payments Method ===================================================================================================
export class PaymentsMethodFilter implements IFilter {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

export class PaymentsMethodSearch implements ISearch {
  name: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.description = other.description;
    }
  }
}
// Payable Receive Installments Method ===================================================================================================
export class PayableReceiveInstallmentsFilter implements IFilter {
  active: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
    }
  }
}

export class PayableReceiveInstallmentsSearch implements ISearch {
  // name: string;
  //
  // constructor(other?: any) {
  //   if (other != null) {
  //     this.name = other.name;
  //   }
  // }
}

// Payable Receive Installments Method ===================================================================================================
export class PayableFilter implements IFilter {
  // created_at: string;
  // updated_at: string;
  // active: true;
  // bill: string;
  // release_date: string;
  // due_date: string;
  // numberOfInstallments: number;
  // amount: number;
  // discount: number;
  // interestRate: number;
  // amount_paid: number;
  // balance: number;
  // description: string;
  // accountId: string;
  // paymentMethodId: string;
  // paymentStatusId: string;
  // accountingId: string;
  // documentTypeId: string;
  // externalDocumentId: string;
  // externalAddressId: string;
  // externalContactId: string;
  // bankConciliationId: string;

  constructor(other?: any) {
    if (other != null) {
      // this.created_at = other.created_at;
      // this.updated_at = other.updated_at;
      // this.active = other.active;
      // this.bill = other.bill;
      // this.release_date = other.release_date;
      // this.numberOfInstallments = other.numberOfInstallments;
      // this.amount = other.amount;
      // this.discount = other.discount;
      // this.interestRate = other.interestRate;
      // this.amount_paid = other.amount_paid;
      // this.balance = other.balance;
      // this.description = other.description;
      // this.accountId = other.accountId;
      // this.paymentMethodId = other.paymentMethodId;
      // this.paymentStatusId = other.paymentStatusId;
      // this.accountingId = other.accountingId;
      // this.documentTypeId = other.documentTypeId;
      // this.externalDocumentId = other.externalDocumentId;
      // this.externalAddressId = other.externalAddressId;
      // this.externalContactId = other.externalContactId;
      // this.bankConciliationId = other.bankConciliationId;
    }
  }
}

export class PayableSearch implements ISearch {
  // created_at: string;
  // updated_at: string;
  // active: true;
  // bill: string;
  // release_date: string;
  // due_date: string;
  // numberOfInstallments: number;
  // amount: number;
  // discount: number;
  // interestRate: number;
  // amount_paid: number;
  // balance: number;
  // description: string;
  // accountId: string;
  // paymentMethodId: string;
  // paymentStatusId: string;
  // accountingId: string;
  // documentTypeId: string;
  // externalDocumentId: string;
  // externalAddressId: string;
  // externalContactId: string;
  // bankConciliationId: string;

  constructor(other?: any) {
    if (other != null) {
      // this.created_at = other.created_at;
      // this.updated_at = other.updated_at;
      // this.active = other.active;
      // this.bill = other.bill;
      // this.release_date = other.release_date;
      // this.numberOfInstallments = other.numberOfInstallments;
      // this.amount = other.amount;
      // this.discount = other.discount;
      // this.interestRate = other.interestRate;
      // this.amount_paid = other.amount_paid;
      // this.balance = other.balance;
      // this.description = other.description;
      // this.accountId = other.accountId;
      // this.paymentMethodId = other.paymentMethodId;
      // this.paymentStatusId = other.paymentStatusId;
      // this.accountingId = other.accountingId;
      // this.documentTypeId = other.documentTypeId;
      // this.externalDocumentId = other.externalDocumentId;
      // this.externalAddressId = other.externalAddressId;
      // this.externalContactId = other.externalContactId;
      // this.bankConciliationId = other.bankConciliationId;
    }
  }
}

// Permission ===================================================================================================
export class PermissionFilter implements IFilter {
  isMenu: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.isMenu = other.isMenu;
    }
  }
}

export class PermissionSearch implements ISearch {
  name: string;
  moduleName: string;
  route: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.moduleName = other.moduleName;
      this.route = other.route;
    }
  }
}

// PermissionScreen ===================================================================================================
export class PermissionScreenFilter implements IFilter {
  active: boolean;
  legalRepresentative: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.legalRepresentative = other.legalRepresentative;
    }
  }
}

export class PermissionScreenSearch implements ISearch {
  name: string;
  email: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.email = other.email;
      this.description = other.description;
    }
  }
}

// PlaceAccount ===================================================================================================
export class PlaceAccountFilter implements IFilter {
  active: boolean;
  placeTypeId = undefined;
  accountId = undefined;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeTypeId = other.placeTypeId;
      this.accountId = other.accountId;
    }
  }
}

// Places ===================================================================================================
export class PlacesFilters implements IFilter {
  active: boolean;
  placeTypeIds: string;
  placeIds: string;
  accountIds: string;

  constructor(other?: any) {
    if (other != null) {
      this.placeTypeIds = other.placeTypeIds;
      this.placeIds = other.placeIds;
      this.accountIds = other.accountIds;
    }
  }
}

export class PlacesFilter implements IFilter {
  active: boolean;
  placeTypeId: string;
  accountId: string;
  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeTypeId = other.placeTypeId;
      this.accountId = other.accountId;
    }
  }
}

export class PlacesSearch implements ISearch {
  tradingName: string;
  companyName: string;

  constructor(other?: any) {
    if (other != null) {
      this.tradingName = other.tradingName;
      this.companyName = other.companyName;
    }
  }
}

export class PlacesSearchs implements ISearch {

  constructor(other?: any) {
    if (other != null) { }
  }
}

// Places Types ===================================================================================================
export class PlaceTypeFilter implements IFilter {
  active: boolean;
  id: string;
  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.id = other.id;
    }
  }
}

export class PlaceTypeSearch implements ISearch {
  name: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.description = other.description;
    }
  }
}
// Products ===================================================================================================
export class ProductsFilter implements IFilter {
  active: boolean;
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.name = other.name;
    }
  }
}

export class ProductsSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// Producer Attendace Invoices ===================================================================================================
export class ProducerAttendaceInvoicesFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class ProducerAttendaceInvoicesSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Producer Attendace Scheduling ===================================================================================================
export class ProducerAttendaceSchedulingFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class ProducerAttendaceSchedulingSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Producer Attendace Steps ===================================================================================================
export class ProducerAttendaceStepsFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class ProducerAttendaceStepsSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Producer Attendace Movement Today ===================================================================================================
export class ProducerAttendaceMovementTodayFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class ProducerAttendaceMovementTodaySearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Producer Attendace Movement Error ===================================================================================================
export class ProducerAttendaceMovementErrorFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class ProducerAttendaceMovementErrorSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// ProducerAttendaceContract ===================================================================================================
export class ProducerAttendaceContractFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class ProducerAttendaceContractSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {

    }
  }
}

// Payable Receive Installments Method ===================================================================================================
export class ReceivableFilter implements IFilter {
  // created_at: string;
  // updated_at: string;
  // active: true;
  // bill: string;
  // release_date: string;
  // due_date: string;
  // numberOfInstallments: number;
  // amount: number;
  // discount: number;
  // interestRate: number;
  // amount_paid: number;
  // balance: number;
  // description: string;
  // accountId: string;
  // paymentMethodId: string;
  // paymentStatusId: string;
  // accountingId: string;
  // documentTypeId: string;
  // externalDocumentId: string;
  // externalAddressId: string;
  // externalContactId: string;
  // bankConciliationId: string;

  constructor(other?: any) {
    if (other != null) {
      // this.created_at = other.created_at;
      // this.updated_at = other.updated_at;
      // this.active = other.active;
      // this.bill = other.bill;
      // this.release_date = other.release_date;
      // this.numberOfInstallments = other.numberOfInstallments;
      // this.amount = other.amount;
      // this.discount = other.discount;
      // this.interestRate = other.interestRate;
      // this.amount_paid = other.amount_paid;
      // this.balance = other.balance;
      // this.description = other.description;
      // this.accountId = other.accountId;
      // this.paymentMethodId = other.paymentMethodId;
      // this.paymentStatusId = other.paymentStatusId;
      // this.accountingId = other.accountingId;
      // this.documentTypeId = other.documentTypeId;
      // this.externalDocumentId = other.externalDocumentId;
      // this.externalAddressId = other.externalAddressId;
      // this.externalContactId = other.externalContactId;
      // this.bankConciliationId = other.bankConciliationId;
    }
  }
}

export class ReceivableSearch implements ISearch {
  // created_at: string;
  // updated_at: string;
  // active: true;
  // bill: string;
  // release_date: string;
  // due_date: string;
  // numberOfInstallments: number;
  // amount: number;
  // discount: number;
  // interestRate: number;
  // amount_paid: number;
  // balance: number;
  // description: string;
  // accountId: string;
  // paymentMethodId: string;
  // paymentStatusId: string;
  // accountingId: string;
  // documentTypeId: string;
  // externalDocumentId: string;
  // externalAddressId: string;
  // externalContactId: string;
  // bankConciliationId: string;

  constructor(other?: any) {
    if (other != null) {
      // this.created_at = other.created_at;
      // this.updated_at = other.updated_at;
      // this.active = other.active;
      // this.bill = other.bill;
      // this.release_date = other.release_date;
      // this.numberOfInstallments = other.numberOfInstallments;
      // this.amount = other.amount;
      // this.discount = other.discount;
      // this.interestRate = other.interestRate;
      // this.amount_paid = other.amount_paid;
      // this.balance = other.balance;
      // this.description = other.description;
      // this.accountId = other.accountId;
      // this.paymentMethodId = other.paymentMethodId;
      // this.paymentStatusId = other.paymentStatusId;
      // this.accountingId = other.accountingId;
      // this.documentTypeId = other.documentTypeId;
      // this.externalDocumentId = other.externalDocumentId;
      // this.externalAddressId = other.externalAddressId;
      // this.externalContactId = other.externalContactId;
      // this.bankConciliationId = other.bankConciliationId;
    }
  }
}

// RegimeInContract ===================================================================================================
export class RegimeInContractFilter implements IFilter {
  active: boolean;
  contractId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.contractId = other.contractId;
    }
  }
}

export class RegimeInContractSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {

    }
  }
}
// Reports Balance ===================================================================================================
export class ReportsBalanceFilter implements IFilter {
  accountId: string;
  placeIds: string;
  rtiTypeIds: string;
  startDate: string;
  endDate: string;

  typeAccount: string;
  constructor(other?: any) {
    if (other != null) {
      this.typeAccount = other.typeAccount;
      this.accountId = other.accountId;
      this.placeIds = other.placeIds;
      this.rtiTypeIds = other.rtiTypeIds;
      this.startDate = other.startDate;
      this.endDate = other.endDate;
    }
  }
}

export class ReportsBalanceSearch implements ISearch {
  constructor(other?: any) {
    if (other != null) { }
  }
}

// Reports Extract Movement ===================================================================================================
export class ReportExtractMovementFilter implements IFilter {

  accountId: string;
  movementTypeIds: Array<string>;
  movementStepIds: Array<string>;
  rtiTypeIds: Array<string> = [];
  placeIds: Array<string> = [];
  dateStart: string;
  dateEnd: string;

  constructor(other?: any) {
    if (other != null) {
      this.accountId = other.accountId;
      this.movementTypeIds = other.movementTypeIds;
      this.movementStepIds = other.movementStepIds;
      this.rtiTypeIds = other.rtiTypeIds;
      this.placeIds = other.placeIds;
      this.dateStart = other.dateStart;
      this.dateEnd = other.dateEnd;
    }
  }
}

export class ReportExtractMovementSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Reports Time Trend ===================================================================================================
export class ReportsTimeTrendFilter implements IFilter {
  accountId: string;
  placeIds: string;
  rtiTypeIds: string;
  startDate: string;
  endDate: string;
  isLoja: boolean;

  typeAccount: string;
  constructor(other?: any) {
    if (other != null) {
      this.typeAccount = other.typeAccount;
      this.accountId = other.accountId;
      this.placeIds = other.placeIds;
      this.rtiTypeIds = other.rtiTypeIds;
      this.startDate = other.startDate;
      this.endDate = other.endDate;
      this.isLoja = other.isLoja;
    }
  }
}

export class ReportsTimeTrendSearch implements ISearch {
  constructor(other?: any) {
    if (other != null) { }
  }
}

// Retailer Attendace Movement Today ===================================================================================================
export class RetailerAttendaceMovementTodayFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class RetailerAttendaceMovementTodaySearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Retailer Attendace Scheduling ===================================================================================================

export class RetailerAttendaceSchedulingFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class RetailerAttendaceSchedulingSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// Producer Attendace Invoices ===================================================================================================
export class RetailerAttendaceInvoicesFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class RetailerAttendaceInvoicesSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {
    }
  }
}

// ProducerAttendaceContract ===================================================================================================
export class RetailerAttendaceContractFilter implements IFilter {
  active: boolean;
  placeId: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.placeId = other.placeId;
      this.accountId = other.accountId;
    }
  }
}

export class RetailerAttendaceContractSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) {

    }
  }
}
// Revenues ===================================================================================================
export class RevenuesFilter implements IFilter {
  accountId: string;
  start: string;
  end: string;
  statusId: string;

  constructor(other?: any) {
    if (other != null) {
      this.accountId = other.accountId;
      this.start = other.start;
      this.end = other.end;
      this.statusId = other.statusId;
    }
  }
}

export class RevenuesSearch implements ISearch {
  constructor(other?: any) {
    if (other != null) { }
  }
}


// Revenues Item ===================================================================================================
export class RevenuesItemFilter implements IFilter {
  active: boolean;
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.name = other.name;
    }
  }
}

export class RevenuesItemSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}
// Revenues Item ===================================================================================================
export class RevenuesItemPriceFilter implements IFilter {
  active: boolean;
  revenueItemId: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.revenueItemId = other.revenueItemId;
    }
  }
}

export class RevenuesItemPriceSearch implements ISearch {
  constructor(other?: any) {
    if (other != null) { }
  }
}

// RTI's ===================================================================================================
export class RtiFilter implements IFilter {
  rti_type_id: string;
  rti_status_id: string;

  constructor(other?: any) {
    if (other != null) {
      this.rti_type_id = other.rti_type_id;
      this.rti_status_id = other.rti_status_id;
    }
  }
}

export class RtiSearch implements ISearch {
  code: string;
  gtin: string;

  constructor(other?: any) {
    if (other != null) {
      this.code = other.code;
      this.gtin = other.gtin;
    }
  }
}
// Revenues - Periods ===================================================================================================
export class RevenuesPeriodsFilter implements IFilter {
  start: string;
  end: string;
  accountId: string;

  constructor(other?: any) {
    if (other != null) {
      this.start = other.start;
      this.end = other.end;
      this.accountId = other.accountId;
    }
  }
}

export class RevenuesPeriodsSearch implements ISearch {

  constructor(other?: any) {
    if (other != null) { }
  }
}


// Stocks ===================================================================================================
export class StocksFilter implements IFilter {
  typeAccount: string;
  accountId: string;
  placeId: string;
  rtiTypeId: string;
  dateStartMovement: string;
  dateEndMovement: string;

  constructor(other?: any) {
    if (other != null) {
      this.typeAccount = other.typeAccount;
      this.accountId = other.accountId;
      this.placeId = other.placeId;
      this.rtiTypeId = other.rtiTypeId;
      this.dateStartMovement = other.dateStartMovement;
      this.dateEndMovement = other.dateEndMovement;
    }
  }
}

export class StocksSearch implements ISearch {
  typeAccount: string;
  accountId: string;
  placeId: string;
  rtiTypeId: string;
  dateStartMovement: string;
  dateEndMovement: string;

  constructor(other?: any) {
    if (other != null) {
      this.typeAccount = other.typeAccount;
      this.accountId = other.accountId;
      this.placeId = other.placeId;
      this.rtiTypeId = other.rtiTypeId;
      this.dateStartMovement = other.dateStartMovement;
      this.dateEndMovement = other.dateEndMovement;
    }
  }
}
// SideMenu ===================================================================================================
export class SideMenuFilter implements IFilter {
  active: boolean;
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.name = other.name;
    }
  }
}

export class SideMenuSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// StatusRTI's ===================================================================================================
export class StatusRTIsFilter implements IFilter {
  active: boolean;
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.name = other.name;
    }
  }
}

export class StatusRTIsSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// Shipping-Company ===================================================================================================
export class ShippingCompanyFilter implements IFilter {
  active: boolean;
  // legalRepresentative: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      //  this.legalRepresentative = other.legalRepresentative;
    }
  }
}

export class ShippingCompanySearch implements ISearch {
  tradingName: string;
  companyName: string;

  constructor(other?: any) {
    if (other != null) {
      this.tradingName = other.tradingName;
      this.companyName = other.companyName;
    }
  }
}

// TypeRTI's ===================================================================================================
export class TypesRtiFilter implements IFilter {
  active: boolean;
  name: string;
  default_pallet_id: string;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.name = other.name;
      this.default_pallet_id = other.default_pallet_id;
    }
  }
}

export class TypesRtiSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}
// Contacts ===================================================================================================
export class TypeRegimeFilter implements IFilter {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

export class TypeRegimeSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// TypesMovement ===================================================================================================
export class TypesMovementFilter implements IFilter {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

export class TypesMovementSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// User Type ===================================================================================================
export class UserTypeFilter implements IFilter {
  active: boolean;

  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
    }
  }
}

export class UserTypeSearch implements ISearch {
  name: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.description = other.description;
    }
  }
}

// UserTypeMovementType ===================================================================================================
export class UserTypeMovementTypeFilter implements IFilter {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

export class UserTypeMovementTypeSearch implements ISearch {
  name: string;

  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
    }
  }
}

// Users ===================================================================================================
export class UserFilter implements IFilter {
  active: boolean;
  usertype: UserType;
  constructor(other?: any) {
    if (other != null) {
      this.active = other.active;
      this.usertype = other.usertype;
    }
  }
}

export class UserSearch implements ISearch {
  name: string;
  email: string;
  places = [];
  constructor(other?: any) {
    if (other != null) {
      this.name = other.name;
      this.email = other.email;
      this.places = other.places;
    }
  }
}

// UserPlaces ===================================================================================================
export class UserPlacesFilter implements IFilter {
  userId: string;

  constructor(other?: any) {
    if (other != null) {
      this.userId = other.userId;
    }
  }
}

export class UserPlacesSearch implements ISearch {
  tradingName: string;
  companyName: string;
  description: string;

  constructor(other?: any) {
    if (other != null) {
      this.tradingName = other.tradingName;
      this.companyName = other.companyName;
      this.description = other.description;
    }
  }
}

// UserPlaces ===================================================================================================
export class UserPlacesAssignedFilter implements IFilter {
  userId: string;
  placeTypeOriginIds = [];
  placeTypeAssignedIds = [];

  constructor(other?: any) {
    if (other != null) {
      this.userId = other.userId;
      this.placeTypeOriginIds = other.placeTypeOriginIds;
      this.placeTypeAssignedIds = other.placeTypeAssignedIds;
    }
  }
}

export class UserPlacesAssignedSearch implements ISearch {
  tradingName: string;

  constructor(other?: any) {
    if (other != null) {
      this.tradingName = other.tradingName;
    }
  }
}

