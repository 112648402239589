import { Injectable } from '@angular/core';
import { BaseProviderService } from './base-provider.service';
import { ILibFormService, ILibTableService } from '../@lib/services/contracts.services';
import { AccountFilter, AccountSearch } from '../models/filters.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterPagination, PaginatedList } from '../@lib/models/commons.models';
import { Account } from '../models/account.model';
import { AccountContacts } from '../models/account-contacts.model';
import {DocumentType} from '../models/document-type.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseProviderService implements ILibTableService<Account, AccountFilter, AccountSearch>, ILibFormService<Account> {

  protected module = 'accounts';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): Account {
    return new Account();
  }

  get(id: string): Observable<Account> {
    return this.http.get<Account>(this.getUrl(this.module, [id]))
      .pipe(map(x => new Account(x)));
  }


  getAccountContact(id: string): Observable<PaginatedList<AccountContacts>> {
    return this.http.get<PaginatedList<AccountContacts>>(this.getUrl(this.module, [id, 'contacts']))
      .pipe(map(d => new PaginatedList<AccountContacts>(AccountContacts, d)));
  }

  list(): Observable<any> {
    return this.http.get(this.getUrl(this.module, ['all']))
      .pipe(map(d => new PaginatedList<Account>(Account, d)));
  }

  listAccountContacts(id: string): Observable<any> {
    return this.http.get(this.getUrl(this.module, [id, 'contacts']))
      .pipe(map(d => new PaginatedList<AccountContacts>(AccountContacts, d)));
  }

  filter(filter: FilterPagination<AccountFilter, AccountSearch>): Observable<PaginatedList<Account>> {
    return this.http.post<PaginatedList<Account>>(this.getUrl(this.module, ['filter']), filter)
      .pipe(map(d => new PaginatedList<Account>(Account, d)));
  }

  update(model: Account): Observable<Account> {
    return this.http.patch<Account>(this.getUrl(this.module, [model.id]), model).pipe(map(x => new Account(x)));
  }

  save(model: Account): Observable<Account> {
    delete model.id;
    return this.http.post<Account>(this.getUrl(this.module), model).pipe(map(x => new Account(x)));
  }

  delete(id: number): Observable<Account> {
    return this.http.delete<Account>(this.getUrl(this.module, [id]))
      .pipe(map(x => new Account(x)));
  }

  updateStatus(id: string, status: boolean): Observable<string> {
    const statusString = (status === true) ? 'true' : 'false';
    return this.http.patch<string>(this.getUrl(this.module, ['status', id, statusString]), null);
  }

}
