import { Injectable } from '@angular/core';
import { BaseProviderService } from './base-provider.service';
import { ILibFormService, ILibTableService } from '../@lib/services/contracts.services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterPagination, PaginatedList } from '../@lib/models/commons.models';
import { Timetrend } from '../models/timetrend.model';
import { ReportsTimeTrendFilter, ReportsTimeTrendSearch } from '../models/filters.model';

@Injectable({
  providedIn: 'root'
})
export class TimeTrendService extends BaseProviderService implements ILibTableService<Timetrend, ReportsTimeTrendFilter, ReportsTimeTrendSearch>, ILibFormService<Timetrend> {
  protected module = 'report';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): Timetrend {
    return new Timetrend();
  }

  get(id: string): Observable<Timetrend> {
    return null;
  }

  list(): Observable<any> {
    return null;
  }

  filter(filter: FilterPagination<ReportsTimeTrendFilter, ReportsTimeTrendSearch>): Observable<PaginatedList<Timetrend>> {
    filter.take = 0;
    return this.http.post<PaginatedList<Timetrend>>(this.getUrl(this.module, ['timetrend']), filter)
      .pipe(map(d => new PaginatedList<Timetrend>(Timetrend, d)));
  }

  update(model: Timetrend): Observable<Timetrend> {
    return null;
  }

  save(model: Timetrend): Observable<Timetrend> {
    return null;
  }

  delete(id: number): Observable<Timetrend> {
    return null;
  }

}
