<!-- Right Sidebar -->
<aside id="rightsidebar" class="right-sidebar" [ngClass]="isOpenSidebar ? 'open' : 'close'"
  (clickOutside)="onClickedOutside($event)">
  <div class="rightSidebarClose" (click)="toggleRightSidebar()"><i class="far fa-times-circle"></i></div>
  <div class="demo-skin"
    [ngStyle]="{'position' : 'relative', 'max-height' : maxHeight+'px', 'max-width' : maxWidth+'px'}"
    [perfectScrollbar]>
<!--    <div class="rightSetting">-->
<!--      <p>GENERAL SETTINGS</p>-->
<!--      <ul class="setting-list list-unstyled m-t-20">-->
<!--        <li>-->
<!--          <div class="form-check">-->
<!--            <div class="form-check m-l-10">-->
<!--              <label class="form-check-label">-->
<!--                <input class="form-check-input" type="checkbox" value="" checked> Save-->
<!--                History-->
<!--                <span class="form-check-sign">-->
<!--                  <span class="check"></span>-->
<!--                </span>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li>-->
<!--          <div class="form-check">-->
<!--            <div class="form-check m-l-10">-->
<!--              <label class="form-check-label">-->
<!--                <input class="form-check-input" type="checkbox" value="" checked> Show-->
<!--                Status-->
<!--                <span class="form-check-sign">-->
<!--                  <span class="check"></span>-->
<!--                </span>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li>-->
<!--          <div class="form-check">-->
<!--            <div class="form-check m-l-10">-->
<!--              <label class="form-check-label">-->
<!--                <input class="form-check-input" type="checkbox" value="" checked> Auto-->
<!--                Submit Issue-->
<!--                <span class="form-check-sign">-->
<!--                  <span class="check"></span>-->
<!--                </span>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li>-->
<!--          <div class="form-check">-->
<!--            <div class="form-check m-l-10">-->
<!--              <label class="form-check-label">-->
<!--                <input class="form-check-input" type="checkbox" value="" checked> Show-->
<!--                Status To All-->
<!--                <span class="form-check-sign">-->
<!--                  <span class="check"></span>-->
<!--                </span>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
    <div class="rightSetting">
      <p>OPÇÕES DE CORES DO MENUS</p>
      <mat-button-toggle-group class="mt-2" [value]="isDarkSidebar ? 'dark' : 'light'">
        <mat-button-toggle (click)="lightSidebarBtnClick()" value="light">Dia</mat-button-toggle>
        <mat-button-toggle (click)="darkSidebarBtnClick()" value="dark">Noite</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="rightSetting">
      <p>COR DO TEMA</p>
      <mat-button-toggle-group class="mt-2" [value]="isDarTheme ? 'dark' : 'light'">
        <mat-button-toggle (click)="lightThemeBtnClick()" value="light">Dia</mat-button-toggle>
        <mat-button-toggle (click)="darkThemeBtnClick()" value="dark">Noite</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="rightSetting">
      <p>COR DA BARRA</p>
      <ul class="demo-choose-skin choose-theme list-unstyled">
        <li data-theme="white" [ngClass]="{'actived': selectedBgColor === 'white'}" (click)="selectTheme('white')">
          <div class="white-theme white-theme-border"></div>
        </li>
        <li data-theme="black" [ngClass]="{'actived': selectedBgColor === 'black'}" (click)="selectTheme('black')">
          <div class="black-theme"></div>
        </li>
        <li data-theme="purple" [ngClass]="{'actived': selectedBgColor === 'purple'}" (click)="selectTheme('purple')">
          <div class="purple-theme"></div>
        </li>
        <li data-theme="blue" [ngClass]="{'actived': selectedBgColor === 'blue'}" (click)="selectTheme('blue')">
          <div class="blue-theme"></div>
        </li>
        <li data-theme="cyan" [ngClass]="{'actived': selectedBgColor === 'cyan'}" (click)="selectTheme('cyan')">
          <div class="cyan-theme"></div>
        </li>
        <li data-theme="green" [ngClass]="{'actived': selectedBgColor === 'green'}" (click)="selectTheme('green')">
          <div class="green-theme"></div>
        </li>
        <li data-theme="orange" [ngClass]="{'actived': selectedBgColor === 'orange'}" (click)="selectTheme('orange')">
          <div class="orange-theme"></div>
        </li>
      </ul>
    </div>
  </div>
</aside>
<!-- #END# Right Sidebar -->
