import { Injectable } from '@angular/core';
import {BaseProviderService} from './base-provider.service';
import {ILibFormService, ILibTableService} from '../@lib/services/contracts.services';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterPagination, PaginatedList} from '../@lib/models/commons.models';
import {map} from 'rxjs/operators';
import {Balance} from '../models/balance.model';
import {ReportsBalanceFilter, ReportsBalanceSearch} from '../models/filters.model';

@Injectable({
  providedIn: 'root'
})
export class BalanceService extends BaseProviderService implements ILibTableService<Balance, ReportsBalanceFilter, ReportsBalanceSearch>, ILibFormService<Balance> {
  protected module = 'report';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): Balance {
    return new Balance();
  }

  get(id: string): Observable<Balance> {
    return null;
  }

  list(): Observable<any> {
    return null;
  }

  filter(filter: FilterPagination<ReportsBalanceFilter, ReportsBalanceSearch>): Observable<PaginatedList<Balance>> {
    filter.take = 0;
    return this.http.post<PaginatedList<Balance>>(this.getUrl(this.module, ['balance']), filter)
      .pipe(map(d => new PaginatedList<Balance>(Balance, d)));
  }

  update(model: Balance): Observable<Balance> {
    return null;
  }

  save(model: Balance): Observable<Balance> {
    return null;
  }

  delete(id: number): Observable<Balance> {
    return null;
  }

}
