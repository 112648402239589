import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactType} from './contact-type.model';
import {Auditable} from '../@lib/models/commons.models';
import {IForm, IModel} from '../@lib/models/contracts.models';
import {Phone} from './phone.model';
import * as moment from 'moment';

export class Contact  extends Auditable implements IModel, IForm {
  id: string;
  created_at: Date;
  updated_at: Date;
  created_id: string;
  created_by: string;
  update_id: string;
  update_by: string;
  active: boolean;
  name: string;
  description: string;
  email: string;
  legalRepresentative: boolean;
  phones: Array<Phone> = [];
  form: FormGroup;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.id = other.id;
      this.created_at = other.created_at;
      this.updated_at = other.updated_at;
      this.created_id = other.created_id;
      this.created_by = other.created_by;
      this.update_id = other.update_id;
      this.update_by = other.update_by;
      this.active = other.active;
      this.name = other.name;
      this.description = other.description;
      this.email = other.email;
      this.legalRepresentative = other.legalRepresentative;

      this.phones = new Array<Phone>();
      if (other.phones != null && other.phones instanceof Array) {
        other.phones.forEach((value) => this.phones.push(new Phone(value)));
      }
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {

      let phones = [];
      this.phones.forEach((value) => phones.push(value.asForm));

      if (this.id){
        this.form = new FormGroup({
          id: new FormControl(this.id),
          name: new FormControl(this.name, [Validators.required]),
          description: new FormControl(this.description),
          email: new FormControl(this.email, [Validators.required]),
          active: new FormControl(this.active, [Validators.required]),
          legalRepresentative: new FormControl(this.legalRepresentative, [Validators.required]),
          phones: new FormArray(phones),
        });
      } else {
        this.form = new FormGroup({
          name: new FormControl(this.name, [Validators.required]),
          description: new FormControl(this.description),
          email: new FormControl(this.email, [Validators.required]),
          active: new FormControl(true, [Validators.required]),
          legalRepresentative: new FormControl(false, [Validators.required]),
          phones: new FormArray(phones),
        });
      }
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}
