import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExtractMovementComponent } from './extract-movement/extract-movement.component';
import { TimeTrendComponent } from './time-trend/time-trend.component';
import {BalanceComponent} from './balance/balance.component';
import {TimeTrendStoreComponent} from './time-trend-store/time-trend-store.component';

const routes: Routes = [
  {
    path: 'extract-movement',
    component: ExtractMovementComponent
  },
  {
    path: 'timetrend',
    component: TimeTrendComponent
  },
  {
    path: 'timetrend-store',
    component: TimeTrendStoreComponent
  },
  {
    path: 'balance',
    component: BalanceComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
