<section class="content">
  <div class="container-fluid">
    <div>
      <!-- Titulo -->
      <h1>TimeTrend - Loja</h1>
      <br>
      <!--Conteudo -->
      <div>
        <div class="row">
          <div class="col-sm-12">
            <br>
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Filtrar
                  </mat-panel-title>
                  <mat-panel-description>
                     
                    <mat-icon>search</mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="expansion-panel-m-2">
                  <form [formGroup]="filter" (ngSubmit)="onFilter()" class="row">
                    <div class="col-sm-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Data início do Movimento</mat-label>
                        <input matInput [matDatepicker]="startDate" formControlName="startDate">
                        <mat-datepicker-toggle matSuffix [for]="startDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Data Fim do Movimento</mat-label>
                        <input matInput [matDatepicker]="endDate" formControlName="endDate">
                        <mat-datepicker-toggle matSuffix [for]="endDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                      <app-lookup [service]="typeRtiService" [fieldName]="'name'" [title]="'RTIs'"
                        (optionSelected)="getRTISelected($event)" clearAndBlurAfterSelect>
                      </app-lookup>
                    </div>
                    <div class="col-sm-6">
                      <mat-form-field appearance="outline" *ngIf="list.rtiTypes.length > 0" style="width: 100%;">
                        <mat-label>RTIs</mat-label>
                        <mat-chip-list #chipList aria-label="Seleção de RTIs">
                          <mat-chip *ngFor="let rtiType of list.rtiTypes" selectable="true" removable="true"
                            (removed)="removeItem(rtiType, 'rtiTypes')">
                            {{rtiType.name}}
                            <button matChipRemove>
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip>
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                    <!-- <div class="col-sm-4">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Tipo do Conta</mat-label>
                        <mat-select id="type"
                                    (selectionChange)="getTypeAccountSelected($event.value)">
                          <mat-option *ngFor="let select of accountTypeList"
                                      [value]="select.key">
                            {{select.value}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div> -->
                    <div class="col-sm-4">
                      <app-lookup [service]="accountService" [fieldName]="'tradingName'" [title]="'Contas'"
                        (optionSelected)="getAccountSelected($event)" isServerSide
                        [filterPagination]="accountFilterPagination" autoSelectById>
                      </app-lookup>
                    </div>
                    <div class="col-sm-4">
                      <mat-form-field appearance="outline" class="w-100" *ngIf="viewPlaces">
                        <mat-label>Locais</mat-label>
                        <input type="text" placeholder="Selecione um local" aria-label="Number" matInput
                          [formControl]="myControl" [matAutocomplete]="auto" #placeInput>
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                          (optionSelected)="getPlacesDestinyTypeSelected(myControl.value, placeInput)">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.tradingName}} | {{option.companyName}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-5"></div>
                    <div class="col-sm-7">
                      <mat-form-field appearance="outline" *ngIf="list.places.length > 0" style="width: 100%;">
                        <mat-label>Locais</mat-label>
                        <mat-chip-list #chipList aria-label="Seleção de Locais">
                          <mat-chip *ngFor="let place of list.places" selectable="true" removable="true"
                            (removed)="removeItem(place, 'places')">
                            {{place.tradingName}}
                            <button matChipRemove>
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip>
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-12">
                      <button class="m-l-button btn btn-primary float-right" type="submit" id="filter"
                        [disabled]="!validForm">
                        <i class="ti-filter" aria-hidden="true"></i>
                        Pesquisar
                      </button>
                      <button type="button" class="btn btn-danger float-right" (click)="clearFilter()"><i
                          class="ti-trash" aria-hidden="true"></i> Limpar Pesquisa</button>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <br>
        <div class="card">
          <div class="body">
            <app-export [rows]="report" [fileName]="'report-extract-movement'"></app-export>

            <ngx-datatable class="material" [rowHeight]="'auto'" [columnMode]="'standard'" [reorderable]="true"
              [headerHeight]="height" [footerHeight]="height" [externalPaging]="paging" [loadingIndicator]="loading"
              [messages]="messages" [rows]="rows" [offset]="offset" [count]="pagination.total" [limit]="pagination.take"
              (page)="onPage($event)" [summaryPosition]="'bottom'" (sort)="onSort($event)" [scrollbarH]="true"
              [summaryRow]="true" [summaryHeight]="60">
              <ngx-datatable-column [resizeable]="true" [frozenLeft]="true" name="Data" prop="date">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.date !== 'Total'">{{formatDate(row.date)}}</span>
                  <span *ngIf="row.date === 'Total'"><strong>{{row.date}}</strong></span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="true" name="Tipo da Conta" prop="accountId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row?.account?.type}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="true" name="Conta" prop="accountId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row?.account?.tradingName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="true" name="Local" prop="placeId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row?.place?.tradingName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="true" name="RTI" prop="rtiTypeId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row?.rtiType?.name}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="true" name="Saldo Total" prop="finalBalanceStore">
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="true" name="Movimento Total" prop="totalMovement">
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="true" name="Movimento Remessa" prop="remittance"></ngx-datatable-column>
              <ngx-datatable-column [resizeable]="true" name="Movimento Regresso" prop="regress">
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>