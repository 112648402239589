import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ExtractMovementComponent } from './extract-movement/extract-movement.component';
import { TimeTrendComponent } from './time-trend/time-trend.component';
import { BalanceComponent } from './balance/balance.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { TimeTrendStoreComponent } from './time-trend-store/time-trend-store.component';


@NgModule({
  declarations: [
    ExtractMovementComponent,
    TimeTrendComponent,
    BalanceComponent,
    TimeTrendStoreComponent,
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    ComponentsModule,
  ],
  providers: [{provide: LOCALE_ID, useValue: 'pt-BR'}],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReportsModule { }
