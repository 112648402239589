<div class="row">
	<div class="col-sm-12 text-center">
		<h1 mat-dialog-title>{{data.title}}</h1>
		<div mat-dialog-content>
			{{ data.message }}
		</div>
		<div class="row" style="margin-top: 15px">
			<div [class.col-sm-6]="data.btnOkText">
				<button mat-flat-button (click)="decline()" *ngIf="data.btnCancelText"><i class="ti-back-left"></i>
                    Cancelar</button>
				<!-- <button class="btn btn-outline-primary " (click)="decline()" *ngIf="data.btnCancelText">{{ data.btnCancelText }}</button> -->
		
			</div>

			<div [class.col-sm-6]="data.btnCancelText" [class.col-sm-12]="!data.btnCancelText">
				<button mat-flat-button class="btn-success" (click)="accept()" *ngIf="data.btnOkText">Confirmar <i class="ti-save"></i></button>
					<!-- <button class="btn btn-primary " (click)="accept()" *ngIf="data.btnOkText"> {{ data.btnOkText }}</button> -->
			</div>

		</div>
	</div>
</div>