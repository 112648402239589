import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { AuthService } from './../../service/auth.service';

import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  // user = null;
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const user = await this.authService.getRolestypes();
    const loggedIn = !!user;
    // if (!this.user){
    //   this.user = await this.authService.getRolestypes();
    // }

    // const loggedIn = !!this.user;

    if (loggedIn) {
      return true;
    }
    this.router.navigate(['/authentication/signin']);
    return false;
  }
}
