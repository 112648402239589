import { HttpParams } from '@angular/common/http';
import { IFilter, IFilterConstructor, IModel, IModelConstructor, ISearch, ISearchConstructor } from './contracts.models';
import { SortType } from '../enums/commons.enum';


/**
 * Classe de par Chave-Valor
 * @typeparam TKey Tipo utilizado na chave
 * @typeparam TValue Tipo utilizado no valor
 */
export class Pair<TKey, TValue> {
    key: TKey;
    value: TValue;

    /**
     * Construtor da classe de par chave-valor
     * @param key Chave do tipo definido na classe
     * @param value Valor do tipo definido na classe
     */
    constructor(key: TKey, value: TValue) {
        this.key = key;
        this.value = value;
    }
}

/**
 * Classe genérica de Model
 * @typeparam T Modelo de dado que implementa a interface [[IModel]]
 */
 export class TObject<T extends IModel> {
    active:boolean;
    model: T;

    /**
     * Construtor da lista paginada
     * @param ctor Definição do construtor de objetos da lista
     * @param obj Objeto 
     */
    constructor(ctor: IModelConstructor<T>, obj: any) {
        if (obj != null) {
           this.model = new ctor(obj)
        }
    }
}


/**
 * Classe genérica de Lista paginada
 * @typeparam T Modelo de dado que implementa a interface [[IModel]]
 */
export class PaginatedList<T extends IModel> {
    data: Array<T>;
    currentPage: number;
    skip: number;
    total: number;
    take: number;

    /**
     * Construtor da lista paginada
     * @param ctor Definição do construtor de objetos da lista
     * @param list Objeto de lista
     */
    constructor(ctor: IModelConstructor<T>, list: any) {
        if (list != null) {
            this.currentPage = (list.hasOwnProperty('currentPage')) ? list.currentPage : 0;
            this.skip = (list.hasOwnProperty('skip')) ? list.skip : 0;
            this.total = (list.hasOwnProperty('total')) ? list.total : 0;
            this.take = (list.hasOwnProperty('take')) ? list.take : 20;

            if (list.hasOwnProperty('data') && list.data instanceof Array) {
                this.data = new Array<T>();
                for (const value of list.data) {
                    this.data.push(new ctor(value));
                }
            }
        }
    }

    public mapData(key: string = 'id'): Map<number, T> {
        // tslint:disable-next-line: prefer-const
        let map = new Map<number, T>();
        this.data.forEach(x => map.set(x[key], x));
        return map;
    }
}


/**
 * Classe de paginação
 */
export class Pagination {
    sortField: string;
    skip: number;
    take: number;
    sortOrder: SortType;

    /**
     * Construtor da paginação
     * @param other Objeto de paginação ou número da página
     * @param pageSize Sem definição ou número de itens por página
     */
    constructor(other?: any, take: number = 20) {
        if (other != null) {
            if (typeof other === 'object') {
                this.skip = other.skip || 0;
                this.take = other.take || 20;
                this.sortOrder = other.sortOrder || SortType.Ascending;
                this.sortField = other.sortField || null;
            } else {
                this.skip = other;
                this.take = take;
                this.sortOrder = SortType.Ascending;
                this.sortField = '';
            }
        }
    }

    toHttpParams(): HttpParams {
        let params = new HttpParams()
            .set('skip', this.skip.toString())
            .set('take', this.take.toString());

        if (this.sortField != null && this.sortField != '') {
            params = params
                .append('sortField', this.sortField)
                .append('sortOrder', this.sortOrder.toString());
        }
        return params;
    }
}


/**
 * Classe de filtro com paginação
 * @typeparam TFilter Objeto de filtro que implementa a interface [[IFilter]]
 */
export class FilterPagination<TFilter extends IFilter, TSearch extends ISearch> extends Pagination {
    filters: TFilter;
    search: TSearch;

    /**
     * Construtor do filtro com paginação
     * @param ctor Definição do construtor de objetos de filtro
     * @param cstor Definição do construtor de objetos de search
     * @param other Objeto de paginação
     * @param filters Objeto de filtro
     */
    constructor(ctor: IFilterConstructor<TFilter>, cstor: ISearchConstructor<TSearch>, other?: any, filters?: any, search?: any) {
        super(other);

        if (filters != null) {
            this.filters = new ctor(filters);
        }
        if (search != null) {
            this.search = new cstor(search);
        }
    }

}


/**
 * Classe de item de erro
 */
export class ErrorItem {
    code: number;
    message: string;
    field: string;

    /**
     * Construtor do item de erro
     * @param other Objeto de erro com código, mensagem e campo ou string de mensagem de erro sem código e sem campo
     */
    constructor(other: any) {
        if (other != null) {
            if (typeof other == 'string') {
                this.code = 0;
                this.message = other;
                this.field = '';
            } else {
                this.code = other.errorCode || 0;
                this.message = other.message || '';
                this.field = other.field || '';
            }
        }
    }
}


/**
 * Classe de lista de erros
 */
export class ErrorList {
    errorList: Array<ErrorItem>;

    get error(): ErrorItem { return (this.errorList.length > 0) ? this.errorList[0] : null; }

    /**
     * Construtor da lista de erros
     * @param other String com erro mensagem de erro para lista com um item ou objeto com lista de erros
     */
    constructor(other: any) {
        if (other != null) {
            this.errorList = new Array<ErrorItem>();

            if (typeof other == 'string') {
                this.errorList.push(new ErrorItem(other));
            } else if (other.errorList != null && other.errorList.length > 0) {
                for (const error of other.errorList) {
                    this.errorList.push(new ErrorItem(error));
                }
            }
        }
    }
}



/**
 * Classe de Status de retorno
 */
export class StatusBoolean {
    status = false;

    constructor(other?: any) {
        if (other != null) {
            if (typeof (other) === 'boolean') {
                this.status = other;
            } else if (typeof (other) === 'object' && other.hasOwnProperty('status')) {
                this.status = other.status;
            }
        }
    }
}

/**
 * Classe de Status de retorno com genérico
 */
export class Status<T> {
    status: T = null;

    constructor(other?: any) {
        if (other != null) {
            if (typeof (other) === 'object' && other.hasOwnProperty('status')) {
                this.status = other.status;
            } else {
                this.status = other;
            }
        }
    }
}

export class Auditable {
    // createUserId: number;
    created_at: Date;
    // updateUserId: number;
    updated_at: Date;

    constructor(other?: any) {
        if (other != null) {
            //   this.createUserId = other.createUserId;
            this.created_at = other.created_at;
            //   this.updateUserId = other.updateUserId;
            this.updated_at = other.updated_at;
        }
    }
}


export class Searchable {
    filtereds: Array<any> = [];
    loading = false;


    constructor() {

    }
}
