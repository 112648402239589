import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ILibTableService } from '../services/contracts.services';
import { AlertService } from '../services/alert.service';
import { SortType } from '../enums/commons.enum';
import { IFilter, IFilterConstructor, IModel, ISearch, ISearchConstructor, } from '../models/contracts.models';
import { LibTableMessage, LibTablePagination } from '../models/tables.models';
import { FilterPagination } from '../models/commons.models';

/**
 * Classe abstrata do Componente de Tabela da Lib
 * @typeparam TModel Classe de modelo das linhas da tabela que implementa a interface [[IModel]]
 * @typeparam TFilter Classe de filtro da tabela que implementa a interface [[IFilter]]
 */
@Injectable()
export abstract class LibTableComponent<
  TModel extends IModel,
  TFilter extends IFilter,
  TSearch extends ISearch
  > implements OnInit, OnDestroy {
  messages: LibTableMessage = new LibTableMessage([
    'Nenhum item encontrado.',
    'Total',
  ]);
  filter: FormGroup;
  search: FormGroup;
  title = '';
  offset = 0;
  height = 60;
  paging = true;
  loading = false;

  pagination: LibTablePagination = new LibTablePagination();
  rows: Array<TModel> = new Array<TModel>();
  report: Array<any> = new Array<any>();
  /**
   * Construtor da classe de componente de tabela
   * @param filterConstructor Construtor do filtro
   * @param searchConstructor Construtor da pesquisa
   * @param formBuilder Form Builder padrão do angular para construção de ReactiveForms
   * @param alertService Serviço de alerta
   * @param service Serviço de filtro do modelo que implementa a interface de serviço para formulário
   * @param title Texto do título da tabela
   * @param sortField Nome do campo que será ordenado
   * @param filterConfig Objeto de configuração do formulário de filtro como ReactiveForms
   * @param searchConfig Objeto de configuração do formulário de Search como ReactiveForms
   * @param autoLoad Se será carregado ao abrir a tela
   * @param nameSaveFilter Nome do filtro para salvar no localStorage, se não for enviado. Não irá salvar no localStorage
   */
  protected constructor(
    private filterConstructor: IFilterConstructor<TFilter>,
    private searchConstructor: ISearchConstructor<TSearch>,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService,
    protected service: ILibTableService<TModel, TFilter, TSearch>,
    title: string,
    sortField: string,
    filterConfig: object,
    searchConfig: object,
    private autoLoad = true,
    private nameSaveFilter = ''
  ) {
    this.title = title;
    this.filter = this.formBuilder.group(filterConfig);
    this.search = this.formBuilder.group(searchConfig);
    this.pagination.sortField = sortField;
    this.loadFilterLocalStorage();
  }

  ngOnInit() {
    this.onPage({ offset: 0 });
  }

  ngOnDestroy() { }

  onPage(pageInfo) {
    // tslint:disable-next-line: radix
    this.navigate(parseInt(pageInfo.offset));

    if (this.autoLoad) {
      this.update();
    } else {
      this.autoLoad = true;
    }

  }

  onSort(sortInfo) {
    this.sort(sortInfo.sorts[0].prop, sortInfo.sorts[0].dir);
    this.update();
  }

  onFilter() {
    this.saveFilterLocalStorage();
    this.update();
  }

  onBeforeUpdate() { }
  onAfterUpdate(success: boolean) { }

  isFiltering() {
    for (const key in this.filter.controls) {
      if (this.filter.controls[key].value != '') { return true; }
    }
    return false;
  }

  clearFilter() {
    // tslint:disable-next-line: forin
    for (const key in this.filter.controls) {
      this.filter.controls[key].setValue('');
    }
    this.saveFilterLocalStorage(true);
    this.onAfterClearFilter();
  }

  clearSearch(): void {
    // tslint:disable-next-line: forin
    for (const key in this.search.controls) {
      this.search.controls[key].setValue('');
    }
  }

  onAfterClearFilter(): void { }

  saveFilterLocalStorage(remove = false) {
    // Salvar o filtro para poder carregar novamente
    if (this.nameSaveFilter !== '') {
      if (remove) {
        localStorage.removeItem(this.nameSaveFilter);
      } else {
        localStorage.setItem(this.nameSaveFilter, JSON.stringify(this.filter.value));
      }
    }
  }

  loadFilterLocalStorage() {
    if (this.nameSaveFilter !== '' && localStorage.getItem(this.nameSaveFilter) !== null) {
      this.filter.patchValue(JSON.parse(localStorage.getItem(this.nameSaveFilter)));
    }
  }

  get f() {
    return this.filter;
  }
  get fc() {
    return this.filter.controls;
  }

  protected navigate(offset?: number) {
    if (offset != null) {
      this.offset = offset;
      this.pagination.skip = offset * this.pagination.take;
      this.pagination.pageNumber = this.offset + 1;
    }
  }

  protected sort(field: string, order: string) {
    this.pagination.sortOrder =
      order === 'desc' ? SortType.Descending : SortType.Ascending;
    this.pagination.sortField = field == '' ? null : field;
  }

  protected update() {
    this.loading = true;
    this.onBeforeUpdate();
    this.service
      .filter(
        new FilterPagination(
          this.filterConstructor,
          this.searchConstructor,
          this.pagination,
          this.filter.value,
          this.search.value
        )
      )
      .subscribe(
        (data) => {
          this.pagination.update(data);
          this.rows = data.data as Array<TModel>;
          this.loading = false;

          // Verificar se a propriedade do report foi criada, se sim, pega os dados mapeados, senão envia os dados padrão
          if (this.rows.length > 0 && (this.rows[0] as any).report) {
            this.report = (this.rows as any).map(elem => elem.report);
          } else {
            this.report = this.rows;
          }
          this.onAfterUpdate(true);
        },
        (error) => {
          this.rows = new Array<TModel>();
          this.loading = false;
          if (error.error != null) {
            this.alertService.error(error.error.message);
          }
          this.onAfterUpdate(false);
        }
      );
  }

  protected remove(code: string | number, typeDel: string, name: string) {
    const message = 'Exclusão ' + typeDel + ': ' + name;
    this.alertService.dialog(message, 'Deseja continuar?').subscribe(result => {

      if (result) {
        this.service.delete(code).subscribe(
          (d) => {
            this.alertService.success('Excluído com sucesso.');
            this.update();
          },
          (e) => {
            this.alertService.error(e.error.message);
          }
        );
      } else {
        this.alertService.message('Exclusão Cancelada.');

      }
    });
  }

}
