import { GenericTypeEnum } from '../../../@lib/enums/commons.enum';

export enum ExportType {
    JSON = 'json',
    CSV = 'csv',
    HTML = 'html',
    TXT = 'txt',
    AOA = 'aoa'
}

export class ExportTypeEnum extends GenericTypeEnum<ExportType> {
    static readonly JSON = new ExportTypeEnum(ExportType.JSON, 'JSON');
    static readonly CSV = new ExportTypeEnum(ExportType.CSV, 'CSV');
    static readonly HTML = new ExportTypeEnum(ExportType.HTML, 'HTML');
    static readonly TXT = new ExportTypeEnum(ExportType.TXT, 'TXT');
    static readonly AOA = new ExportTypeEnum(ExportType.AOA, 'AOA');
}
