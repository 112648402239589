export class FirebaseErrors {
    static Parse(errorCode: string): string {

        let message: string;

        switch (errorCode) {
            case 'auth/wrong-password':
                message = 'Credenciais de login inválidas.';
                break;
            case 'auth/network-request-failed':
                message = 'Por favor, verifique sua conexão à internet';
                break;
            case 'auth/too-many-requests':
                message =
                    'Detectamos muitas solicitações do seu dispositivo. Faça uma pausa por favor!';
                break;
            case 'auth/user-disabled':
                message =
                    'Sua conta foi desativada ou excluída. Por favor contacte o administrador do sistema.';
                break;
            case 'auth/requires-recent-login':
                message = 'Faça login novamente e tente novamente!';
                break;
            case 'auth/email-already-exists':
                message = 'O endereço de e-mail já está em uso por um usuário existente.';
                break;
            case 'auth/user-not-found':
                message =
                    'Não foi possível encontrar a conta de usuário associada ao endereço de e-mail ou número de telefone.';
                break;
            case 'auth/phone-number-already-exists':
                message = 'O número de telefone já está em uso por um usuário existente.';
                break;
            case 'auth/invalid-phone-number':
                message = 'O número de telefone não é um número de telefone válido!';
                break;
            case 'auth/invalid-email  ':
                message = 'O endereço de e-mail não é um endereço de e-mail válido!';
                break;
            case 'auth/cannot-delete-own-user-account':
                message = 'Você não pode excluir sua própria conta de usuário.';
                break;
            default:
                message = 'Ops! Algo deu errado. Tente mais tarde.';
                break;
        }

        return message;
    }
}