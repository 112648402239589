import { GenericTypeEnum } from '../@lib/enums/commons.enum';

export enum AccountType {
  HB = 'HB',
  RETAILER = 'Varejista',
  PRODUCER = 'Produtor',
  AUDIT = 'Auditoria',
}

export class AccountTypeEnum extends GenericTypeEnum<AccountType> {
  static readonly HB = new AccountTypeEnum(AccountType.HB, 'HB');
  static readonly Retailer = new AccountTypeEnum(AccountType.RETAILER, 'Varejista');
  static readonly Producer = new AccountTypeEnum(AccountType.PRODUCER, 'Produtor');
  static readonly Audit = new AccountTypeEnum(AccountType.AUDIT, 'Auditoria');
}
