/**
 * Classe de Helper para Enumeradores
 * @typeparam T Definição de enumerador do Helper
 */
export class GenericTypeEnum<T> {
    protected static Enums: Map<any, any>;

    protected constructor(public key: T, public value: string) {
    }

    public static getTypes(): Array<any> {
        if (this.Enums == null) {
            this.update();
        }

        return Array.from(this.Enums.values());
    }

    public static getMap(): Map<any, any> {
        if (this.Enums == null) {
            this.update();
        }

        return new Map<any, any>(this.Enums);
    }

    public static getName(type: any): string {
        if (this.Enums == null) {
            this.update();
        }
        // tslint:disable-next-line: prefer-const
        let item = this.Enums.get(type);
        return (item != null) ? item.value : '';
    }

    public static getType(type: any): number {
        if (this.Enums == null) {
            this.update();
        }
        return this.Enums.get(type).key;
    }

    private static update() {
        // tslint:disable-next-line: prefer-const
        let list = Object.values(this).filter((v) => v instanceof GenericTypeEnum);
        this.Enums = new Map<any, any>(list.map(v => [v.key, v] as [any, any]));
    }
}

/**
 * Enumerador de tipo de ordenação (Crescente ou Decrescente)
 */
export enum SortType {
    Ascending = 'ASC',
    Descending = 'DESC'
}

/**
 * Classe do enumerador de Ordenação para o enum SortType
 */
export class SortEnum extends GenericTypeEnum<SortType> {
    static readonly Ascending = new SortEnum(SortType.Ascending, 'asc');
    static readonly Descending = new SortEnum(SortType.Descending, 'desc');
}

/**
 * Enumerador de tipo de formulário (Criação ou Edição)
 */
export enum FormType {
    Create = 0,
    Edit
}

/**
 * Classe do enumerador de Formulário para o enum FormType
 */
export class FormEnum extends GenericTypeEnum<FormType> {
    static readonly Create = new FormEnum(FormType.Create, 'criar');
    static readonly Edit = new FormEnum(FormType.Edit, 'editar');
}

