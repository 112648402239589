import { Account } from 'src/app/models/account.model';
import { MovementStepService } from 'src/app/service/movement-step.service';
import { MovementTypeService } from 'src/app/service/movement-type.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LibTableComponent } from '../../../@lib/components/lib-table.component';
import {
  AccountFilter,
  AccountSearch,
  ForNameSearch,
  PlaceAccountFilter, ReportExtractMovementFilter, ReportExtractMovementSearch
} from '../../../models/filters.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { AlertService } from '../../../@lib/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from '../../../service/account.service';
import { FilterPagination } from '../../../@lib/models/commons.models';
import { PlacesService } from '../../../service/places.service';
import { TypeRtisService } from '../../../service/type-rtis.service';
import { ReportExtractMovementService } from '../../../service/report-extract-movement.service';
import { AccountTypeEnum } from '../../../enum/account-type.enum';
import { ReportExtractMovement } from '../../../models/report-extract-movement.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'report-extract-movement',
  templateUrl: './extract-movement.component.html',
  styleUrls: ['./extract-movement.component.scss']
})
export class ExtractMovementComponent extends LibTableComponent<ReportExtractMovement, ReportExtractMovementFilter, ReportExtractMovementSearch> implements OnInit, OnDestroy {
  accountFilterPagination = new FilterPagination(
    AccountFilter,
    AccountSearch,
    null,
    {
      active: true,
      isPayment: 0,
      type: ''
    },
    {
      companyName: ''
    }
  );
  placesFilterPagination = new FilterPagination(
    PlaceAccountFilter,
    AccountSearch,
    null,
    {
      placeTypeId: undefined,
      accountId: undefined,
    },
    {}
  );
  filterForNamePagination = new FilterPagination(
    ForNameSearch,
    ForNameSearch,
    null,
    null,
    {
      name: ''
    }
  );
  page = 'report';
  step = 0;
  accounts;
  accountTypeList = AccountTypeEnum.getTypes();
  list = {
    places: [],
    movementTypes: [],
    movementSteps: [],
    rtiTypes: []
  };
  listPlaces = [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  viewPlaces = false;
  viewAccounts = false;
  accountIdSelected;
  get validForm(): boolean {
    return (this.fc.dateStart.value) &&
      (this.fc.dateEnd.value) &&
      (this.listPlaces.length > 0 || this.accountIdSelected);
  }

  constructor(
    protected reportExtractMovementService: ReportExtractMovementService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService,
    public dialog: MatDialog,
    protected route: Router,
    public accountService: AccountService,
    public placesService: PlacesService,
    public typeRtiService: TypeRtisService,
    public movementTypeService: MovementTypeService,
    public movementStepService: MovementStepService,
  ) {
    super(
      ReportExtractMovementFilter,
      ReportExtractMovementSearch,
      formBuilder,
      alertService,
      reportExtractMovementService,
      'Relatório Extrato Movimento',
      'date',
      {
        accountId: '',
        movementTypeIds: [],
        movementStepIds: [],
        rtiTypeIds: [],
        placeIds: [],
        dateStart: [],
        dateEnd: [],
      },
      {},
      false
    );
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onAfterUpdate(success: boolean): void {

  }


  onBeforeUpdate() {

    const placeIds = [];
    this.list.places.forEach(async elem => {
      await placeIds.push(elem.id);
    });

    const rtiTypes = [];
    this.list.rtiTypes.forEach(async elem => {
      await rtiTypes.push(elem.id);
    });

    const movementTypes = [];
    this.list.movementTypes.forEach(async elem => {
      await movementTypes.push(elem.id);
    });

    const movementSteps = [];
    this.list.movementSteps.forEach(async elem => {
      await movementSteps.push(elem.id);
    });

    this.fc.placeIds.setValue(placeIds);
    if (placeIds.length === 0) {
      this.fc.accountId.setValue(this.accountIdSelected.id);
    } else {
      this.fc.accountId.setValue('');
    }
    this.fc.rtiTypeIds.setValue(rtiTypes);
    this.fc.movementTypeIds.setValue(movementTypes);
    this.fc.movementStepIds.setValue(movementSteps);

    this.pagination.take = 0; // Carregar todos
    // this.fc.dateStart.setValue(moment(this.fc.dateStart.value).format('YYYY-MM-DD'));
    // this.fc.dateEnd.setValue(moment(this.fc.dateEnd.value).format('YYYY-MM-DD'));
  }

  goRoute(id): void {
    this.route.navigate([]).then(result => { window.open('/moviments/details/' + id, '_blank'); });
  }

  setStep(index: number): void {
    this.step = index;
  }

  getAccountSelected(data): void {
    this.placesFilterPagination.filters.accountId = data.id;
    this.accountIdSelected = data;

    this.placesService.filter(this.placesFilterPagination).subscribe(result => {
      this.listPlaces = result.data;
      this.viewPlaces = true;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
    });
  }

  private _filter(value: any): string[] {
    let filterValue = '';
    if (value.tradingName !== undefined) {
      filterValue = value.tradingName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // remover acentos
    } else {
      filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // remover acentos
    }

    return this.listPlaces.filter(option => option.tradingName.toLowerCase().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '').includes(filterValue));
  }

  getPlacesDestinyTypeSelected(value, placeInput): void {
    if (this.list.places.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.places.push(value);
    }
    this.myControl.setValue('');
    placeInput.blur();
  }
  getTypeAccountSelected(data): void {
    this.viewAccounts = false;
    this.accountFilterPagination.filters.type = data;
    setTimeout(() => {
      this.viewAccounts = true;
      this.accountIdSelected = undefined;
      this.listPlaces = [];
      this.list.places = [];
    }, 300);
  }

  getRTISelected(value): void {
    if (this.list.rtiTypes.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.rtiTypes.push(value);
    }
  }

  getMovementTypesSelected(value): void {
    if (this.list.movementTypes.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.movementTypes.push(value);
    }
  }

  getMovementStepsSelected(value): void {
    if (this.list.movementSteps.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.movementSteps.push(value);
    }
  }

  removeItem(item, type): void {
    let index;
    switch (type) {
      case 'rtiTypes':
        index = this.list.rtiTypes.indexOf(item);

        if (index >= 0) {
          this.list.rtiTypes.splice(index, 1);
        }
        break;
      case 'movementTypes':
        index = this.list.movementTypes.indexOf(item);

        if (index >= 0) {
          this.list.movementTypes.splice(index, 1);
        }
        break;
      case 'movementSteps':
        index = this.list.movementSteps.indexOf(item);

        if (index >= 0) {
          this.list.movementSteps.splice(index, 1);
        }
        break;
      case 'places':
        index = this.list.places.indexOf(item);

        if (index >= 0) {
          this.list.places.splice(index, 1);
        }
        break;
    }
  }

  formatDate(date): string {
    return moment(date).format('DD/MM/YYYY');
  }
}
