import { PLACETYPE } from 'src/app/config/config';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LibTableComponent } from '../../../@lib/components/lib-table.component';
import {
  AccountFilter,
  AccountSearch, ForNameSearch,
  PlaceAccountFilter,
  ReportsTimeTrendFilter,
  ReportsTimeTrendSearch
} from '../../../models/filters.model';
import { FilterPagination } from '../../../@lib/models/commons.models';
import { AccountTypeEnum } from '../../../enum/account-type.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AlertService } from '../../../@lib/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from '../../../service/account.service';
import { PlacesService } from '../../../service/places.service';
import { TypeRtisService } from '../../../service/type-rtis.service';
import { MovementTypeService } from '../../../service/movement-type.service';
import { MovementStepService } from '../../../service/movement-step.service';
import * as moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { TimeTrendService } from '../../../service/time-trend.service';
import { Timetrend } from '../../../models/timetrend.model';

@Component({
  selector: 'app-time-trend',
  templateUrl: './time-trend.component.html',
  styleUrls: ['./time-trend.component.scss']
})
export class TimeTrendComponent extends LibTableComponent<Timetrend, ReportsTimeTrendFilter, ReportsTimeTrendSearch> implements OnInit, OnDestroy {
  get validForm(): boolean {
    return (this.fc.startDate.value) &&
      (this.fc.endDate.value) &&
      (this.listPlaces.length > 0 || this.accountIdSelected);
  }

  constructor(
    protected timeTrendService: TimeTrendService,
    protected formBuilder: FormBuilder,
    protected alertService: AlertService,
    public dialog: MatDialog,
    protected route: Router,
    public accountService: AccountService,
    public placesService: PlacesService,
    public typeRtiService: TypeRtisService,
    public movementTypeService: MovementTypeService,
    public movementStepService: MovementStepService,
  ) {
    super(
      ReportsTimeTrendFilter,
      ReportsTimeTrendSearch,
      formBuilder,
      alertService,
      timeTrendService,
      'Relatório TimeTrend',
      'date',
      {
        accountId: '',
        rtiTypeIds: [],
        placeIds: [],
        startDate: [],
        endDate: [],
        isLoja: false
      },
      {},
      false
    );
  }
  statusRti = false;
  movementTotal = false;
  accountFilterPagination = new FilterPagination(
    AccountFilter,
    AccountSearch,
    null,
    {
      active: true,
      isPayment: 0,
      type: ''
    },
    {
      companyName: ''
    }
  );
  placesFilterPagination = new FilterPagination(
    PlaceAccountFilter,
    AccountSearch,
    null,
    {
      placeTypeId: undefined,
      accountId: undefined,
    },
    {}
  );
  filterForNamePagination = new FilterPagination(
    ForNameSearch,
    ForNameSearch,
    null,
    null,
    {
      name: ''
    }
  );
  page = 'report';
  step = 0;
  accounts;
  accountTypeList = AccountTypeEnum.getTypes();
  list = {
    places: [],
    movementTypes: [],
    movementSteps: [],
    rtiTypes: []
  };
  listPlaces = [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  viewPlaces = false;
  viewAccounts = false;
  accountIdSelected;

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  clearFilter() {
    // tslint:disable-next-line: forin
    for (const key in this.filter.controls) {
      this.filter.controls[key].setValue('');
    }
    this.viewAccounts = false;
    this.viewPlaces = false;
    this.accountIdSelected = undefined;
    this.listPlaces = [];
    this.list.places = [];
    setTimeout(() => {
      this.viewAccounts = true;
    }, 300);
    this.onAfterClearFilter();
  }

  onAfterUpdate(success: boolean): void {
    this.pagination.take = this.pagination.total;
  }

  onBeforeUpdate(): void {
    const placeIds = [];
    this.list.places.forEach(async elem => {
      await placeIds.push(elem.id);
    });
    const rtiTypes = [];
    this.list.rtiTypes.forEach(async elem => {
      await rtiTypes.push(elem.id);
    });
    const movementTypes = [];
    this.list.movementTypes.forEach(async elem => {
      await movementTypes.push(elem.id);
    });
    const movementSteps = [];
    this.list.movementSteps.forEach(async elem => {
      await movementSteps.push(elem.id);
    });
    this.fc.placeIds.setValue(placeIds);
    this.fc.accountId.setValue(this.accountIdSelected.id);
    this.fc.rtiTypeIds.setValue(rtiTypes);

    this.pagination.take = 0; // Carregar todos
    // this.fc.startDate.setValue(moment(this.fc.startDate.value).format('YYYY-MM-DD'));
    // this.fc.endDate.setValue(moment(this.fc.endDate.value).format('YYYY-MM-DD'));
  }

  goRoute(id): void {
    this.route.navigate([]).then(result => { window.open('/moviments/details/' + id, '_blank'); });
  }

  setStep(index: number): void {
    this.step = index;
  }

  getAccountSelected(data): void {
    this.placesFilterPagination.filters.accountId = data.id;

    if (data.type === 'Varejista') {
      this.placesFilterPagination.filters.placeTypeId = PLACETYPE.varejista.id;
      // Para nao carregar as lojas
    }

    this.accountIdSelected = data;

    this.placesService.filter(this.placesFilterPagination).subscribe(result => {
      this.listPlaces = result.data;
      this.viewPlaces = true;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
    });
  }

  private _filter(value: any): string[] {
    let filterValue = '';
    if (value.tradingName !== undefined) {
      filterValue = value.tradingName.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // remover acentos
    } else {
      filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // remover acentos
    }

    return this.listPlaces.filter(option => option.tradingName.toLowerCase().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '').includes(filterValue));
  }

  getPlacesDestinyTypeSelected(value, placeInput): void {
    if (this.list.places.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.places.push(value);
    }
    this.myControl.setValue('');
    placeInput.blur();
  }
  getTypeAccountSelected(data): void {
    this.viewAccounts = false;
    this.accountFilterPagination.filters.type = data;
    setTimeout(() => {
      this.viewAccounts = true;
      this.accountIdSelected = undefined;
      this.listPlaces = [];
      this.list.places = [];
    }, 300);
  }

  getRTISelected(value): void {
    if (this.list.rtiTypes.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.rtiTypes.push(value);
    }
  }

  getMovementTypesSelected(value): void {
    if (this.list.movementTypes.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.movementTypes.push(value);
    }
  }

  getMovementStepsSelected(value): void {
    if (this.list.movementSteps.indexOf(value) === -1) {// se nao tiver sido atribuído ainda
      this.list.movementSteps.push(value);
    }
  }

  removeItem(item, type): void {
    let index;
    switch (type) {
      case 'rtiTypes':
        index = this.list.rtiTypes.indexOf(item);

        if (index >= 0) {
          this.list.rtiTypes.splice(index, 1);
        }
        break;
      case 'movementTypes':
        index = this.list.movementTypes.indexOf(item);

        if (index >= 0) {
          this.list.movementTypes.splice(index, 1);
        }
        break;
      case 'movementSteps':
        index = this.list.movementSteps.indexOf(item);

        if (index >= 0) {
          this.list.movementSteps.splice(index, 1);
        }
        break;
      case 'places':
        index = this.list.places.indexOf(item);

        if (index >= 0) {
          this.list.places.splice(index, 1);
        }
        break;
    }
  }

  formatDate(date): string {
    return moment(date).format('DD/MM/YYYY');
  }
}
