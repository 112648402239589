import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Account } from './account.model';
import { Contact } from './contact.model';

export class AccountContacts {
  id: string;
  active: boolean;
  contactId: string;
  billing: boolean;
  appointment: boolean;
  movement: boolean;
  accountId: Array<Account>;
  contact: Contact;
  form: FormGroup;

  constructor(other?: any) {
    if (other) {
      this.billing = other.billing;
      this.appointment = other.appointment;
      this.movement = other.movement;
      this.contact = other.contact;
      this.accountId = new Array<Account>();
      if (other.accountId != null && other.accountId instanceof Array && other.accountId.length > 0) {
        other.accountId.forEach(x => { this.accountId.push(x); });
      }
      this.contactId = (this.contact) ? this.contact.id : '';
    } else {
      this.billing = false;
      this.appointment = false;
      this.movement = false;
      this.contact = new Contact();
      this.accountId = [];
      this.contactId = '';
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        contactId: new FormControl(this.contactId, [Validators.required]),
        billing: new FormControl(this.billing, [Validators.required]),
        appointment: new FormControl(false),
        movement: new FormControl(this.movement, [Validators.required]),
      });
    }
    return this.form;
  }

  updateForm(other) {
    this.form.patchValue(other);
  }
}


