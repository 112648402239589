import { AutofocusDirective } from './../directives/autofocus.directive';
import { DisableControlDirective } from './../directives/disable-control.directive';
import { EnterToTabDirective } from './../directives/enter-to-tab.directive';
import { NgModule } from '@angular/core';


@NgModule({
    declarations: [
        DisableControlDirective,
        EnterToTabDirective,
        AutofocusDirective
    ],
    imports: [],
    exports: [
        DisableControlDirective,
        EnterToTabDirective,
        AutofocusDirective
    ]
})
export class DirectivesModule { }
