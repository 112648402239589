import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Auditable} from '../@lib/models/commons.models';
import {IForm, IModel} from '../@lib/models/contracts.models';
import {Phone} from './phone.model';

export class TypesMovement  extends Auditable implements IModel, IForm {
  id: string;
  created_id: string;
  created_at: Date;
  updated_at: Date;
  created_by: string;
  update_id: string;
  update_by: string;
  active = true;
  name: string;
  description: string;
  color: string;
  background: string;
  col: string;
  position: number;

  form: FormGroup;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.id = other.id;
      this.created_at = other.created_at;
      this.updated_at = other.updated_at;
      this.created_id = other.created_id;
      this.created_by = other.created_by;
      this.update_id = other.update_id;
      this.update_by = other.update_by;
      this.active = other.active;
      this.name = other.name;
      this.description = other.description;
      this.color = other.color;
      this.background = other.background;
      this.col = other.col;
      this.position = other.position;

    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        id: new FormControl(this.id),
        active: new FormControl(this.active, [Validators.required]),
        name: new FormControl(this.name, [Validators.required]),
        description: new FormControl(this.description, [Validators.required]),
        color: new FormControl(this.color, [Validators.required]),
        background: new FormControl(this.background, [Validators.required]),
        col: new FormControl(this.col, [Validators.required]),
        position: new FormControl(this.position, [Validators.required]),
      });
    }
    return this.form;
  }

  updateForm(other) {
    this.form.patchValue(other);
  }
}

