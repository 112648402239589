import { UserType } from './user-type.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IForm, IListForm, IModel } from '../@lib/models/contracts.models';
import { Auditable } from '../@lib/models/commons.models';
import { UserPermission } from '../models/user-permission.model';
import { Permission } from './permission.model';
import { UserPermissionScreen } from './user-permission-screen.model';
import { UserMovementTypeStep } from './user-movement-type-step.model';

export class User extends Auditable implements IModel, IForm {
  id: string;
  name: string;
  email: string;

  // firebase data
  // tslint:disable-next-line: variable-name
  firebase_uid?: string;
  displayName?: string;
  photoURL?: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  accessToken?: string | null;
  refreshToken?: string;
  expirationTime?: number;

  userPermissions: Array<UserPermission>;
  userMovementTypeStep: Array<UserMovementTypeStep>;

  form: FormGroup;

  usertype?: UserType;
  active: boolean;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.firebase_uid = other.firebase_uid ? other.firebase_uid : other.uid;
      this.displayName = other.displayName;
      this.photoURL = other.photoURL;
      this.emailVerified = other.emailVerified;
      this.phoneNumber = other.phoneNumber;
      this.active = other.active;
      this.accessToken =
        other.stsTokenManager && other.stsTokenManager.accessToken
          ? other.stsTokenManager.accessToken
          : null;
      this.refreshToken =
        other.stsTokenManager && other.stsTokenManager.refreshToken
          ? other.stsTokenManager.refreshToken
          : null;
      this.expirationTime =
        other.stsTokenManager && other.stsTokenManager.expirationTime
          ? other.stsTokenManager.expirationTime
          : null;

      this.id = other.id;
      this.name = other.name;
      this.email = other.email;
      this.usertype = other.usertype;
      this.userPermissions = new Array<UserPermission>();
      if (
        other.userPermissions != null &&
        other.userPermissions instanceof Array &&
        other.userPermissions.length > 0
      ) {
        other.userPermissions.forEach((x) => {
          this.userPermissions.push(x);
        });
      }
      this.userMovementTypeStep = new Array<UserMovementTypeStep>();
      if (
        other.userMovementTypeStep != null &&
        other.userMovementTypeStep instanceof Array &&
        other.userMovementTypeStep.length > 0
      ) {
        other.userMovementTypeStep.forEach((x) => {
          this.userMovementTypeStep.push(x);
        });
      }
    }
  }

  patchValue(other: User) {
    if (other.firebase_uid) { this.firebase_uid = other.firebase_uid; }
    if (other.accessToken) { this.accessToken = other.accessToken; }
    if (other.refreshToken) { this.refreshToken = other.refreshToken; }
    if (other.displayName) { this.displayName = other.displayName; }
    if (other.photoURL) { this.photoURL = other.photoURL; }
    if (other.emailVerified) { this.emailVerified = other.emailVerified; }
    if (other.expirationTime) { this.expirationTime = other.expirationTime; }
    if (other.phoneNumber) { this.phoneNumber = other.phoneNumber; }
    if (other.active) { this.active = other.active; }

    if (other.name) { this.name = other.name; }
    if (other.email) { this.email = other.email; }
    if (other.usertype) { this.usertype = other.usertype; }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        name: new FormControl(this.name, [Validators.required]),
        email: new FormControl(this.email, [Validators.required, Validators.email]),
        usertypeId: new FormControl((this.usertype && this.usertype.id) ? this.usertype.id : '', [Validators.required]),
        firebase_uid: new FormControl(this.firebase_uid, [Validators.required]),
        // defaultContractId: new FormControl(this.defaultContractId, [
        //   Validators.required,
        // ]),
        // permissionId: new FormControl(this.permissionId),
      });
    }
    return this.form;
  }

  updateForm(other) {
    this.form.patchValue(other);
  }
}

export class UserPermissionFlat implements IListForm {
  id: string;
  active = false;
  create = false;
  update = false;
  read = false;
  delete = false;
  permission: Permission;

  permissionId: string;
  permission_active = true;
  permission_name: string;
  permission_moduleName: string;
  permission_type: string;
  permission_route: string;
  permission_icon: string;
  permission_isMenu = false;
  permission_parentId: string;

  form: FormGroup;

  constructor(other?: any) {
    if (other) {
      this.id = other.id;
      this.active = other.active;
      this.create = (other.create) ? other.create : false;
      this.update = (other.update) ? other.update : false;
      this.read = (other.read) ? other.read : false;
      this.delete = (other.delete) ? other.delete : false;
      this.permission = other.permission;
      if (this.permission !== undefined && this.permission !== null) {
        this.permissionId = this.permission.id;
        this.id = this.permissionId;
        this.permission_active = this.permission.active;
        this.permission_name = this.permission.name;
        this.permission_moduleName = this.permission.moduleName;
        this.permission_type = this.permission.type;
        this.permission_route = this.permission.route;
        this.permission_icon = this.permission.icon;
        this.permission_isMenu = this.permission.isMenu;
        this.permission_parentId = this.permission.parentId;
      }
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        create: new FormControl(this.create, [Validators.required]),
        update: new FormControl(this.update, [Validators.required]),
        read: new FormControl(this.read, [Validators.required]),
        delete: new FormControl(this.delete, [Validators.required]),
        permissionId: new FormControl(this.permissionId, [Validators.required]),
      });
    }
    return this.form;
  }

  get prepareToUpsert(): UserPermissionFlat {
    const x = new UserPermissionFlat(this);
    delete x.active;
    delete x.id;
    delete x.permission;
    return x;
  }

  get prepareToRemove(): string {
    return this.id;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}
