import {IModel} from '../@lib/models/contracts.models';
import {TypeRtis} from './typeRtis.model';
import {Places} from './places.model';

export class Balance implements IModel {
  type: string;
  account: string;
  place: string;
  initialBalance: number;
  initialCleanQuantity: number;
  initialDirtyQuantity: number;
  initialBrokenQuantity: number;
  initialNewQuantity: number;
  initialProductQuantity: number;
  initialTransitQuantity: number;
  totalMovement: number;
  retrieve: number;
  back: number;
  return: number;
  transfer: number;
  transfer_state: number;
  withdrawal: number;
  delivery: number;
  audit: number;
  regress: number;
  remittance: number;
  finalBalance: number;
  finalCleanQuantity: number;
  finalDirtyQuantity: number;
  finalBrokenQuantity: number;
  finalNewQuantity: number;
  finalProductQuantity: number;
  finalTransitQuantity: number;
  average_movement: number;
  daily_limit_balance: number;
  average_two_points: number;

  constructor(other?: any) {
    if (other) {
      this.type = other.type;
      this.account = other.account;
      this.place = other.place;
      this.initialBalance = other.initialBalance;
      this.initialCleanQuantity = other.initialCleanQuantity;
      this.initialDirtyQuantity = other.initialDirtyQuantity;
      this.initialBrokenQuantity = other.initialBrokenQuantity;
      this.initialNewQuantity = other.initialNewQuantity;
      this.initialProductQuantity = other.initialProductQuantity;
      this.initialTransitQuantity = other.initialTransitQuantity;
      this.totalMovement = other.totalMovement;
      this.retrieve = other.retrieve;
      this.back = other.back;
      this.return = other.return;
      this.transfer = other.transfer;
      this.transfer_state = other.transfer_state;
      this.withdrawal = other.withdrawal;
      this.delivery = other.delivery;
      this.audit = other.audit;
      this.regress = other.regress;
      this.remittance = other.remittance;
      this.finalBalance = other.finalBalance;
      this.finalCleanQuantity = other.finalCleanQuantity;
      this.finalDirtyQuantity = other.finalDirtyQuantity;
      this.finalBrokenQuantity = other.finalBrokenQuantity;
      this.finalNewQuantity = other.finalNewQuantity;
      this.finalProductQuantity = other.finalProductQuantity;
      this.finalTransitQuantity = other.finalTransitQuantity;
      this.average_movement = other.average_movement;
      this.daily_limit_balance = other.daily_limit_balance;
      this.average_two_points = other.average_two_points;
    }
  }

  get report() {
    return {
      'Tipo da Conta': this.type,
      Conta: this.account,
      Local: this.place,
      'Saldo Inicial Total': this.initialBalance,
      'Saldo Inicial Limpa': this.initialCleanQuantity,
      'Saldo Inicial Suja': this.initialDirtyQuantity,
      'Saldo Inicial Produto': this.initialProductQuantity,
      'Saldo Inicial Avariada': this.initialBrokenQuantity,
      'Saldo Inicial Nova': this.initialNewQuantity,
      'Saldo Inicial Trânsito': this.initialTransitQuantity,
      'Movimento Total': this.totalMovement,
      'Movimento Retiradas': this.withdrawal,
      'Movimento Devolução': this.back,
      'Movimento Entrega': this.delivery,
      'Movimento Devolução de Entrega': this.retrieve,
      'Movimento Transferência de Local': this.transfer,
      'Movimento Retorno': this.return,
      'Movimento Auditoria': this.audit,
      'Movimento Remessa': this.remittance,
      'Movimento Regresso': this.regress,
      'Saldo Final Total': this.finalBalance,
      'Saldo Final Limpa': this.finalCleanQuantity,
      'Saldo Final Suja': this.finalDirtyQuantity,
      'Saldo Final Produto': this.finalProductQuantity,
      'Saldo Final Avariada': this.finalBrokenQuantity,
      'Saldo Final Nova': this.finalNewQuantity,
      'Saldo Final Trânsito': this.finalTransitQuantity,
      'Média do Movimento': this.average_movement,
      'Saldo Médio em Dias': this.daily_limit_balance,
      'Saldo Médio em Dias Corrigdo': this.average_two_points
    };
  }
}

