import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import firebase from 'firebase/app';
import { AngularFireStorage } from '@angular/fire/storage';

import { UserService } from './user.service';
import { FirebaseErrors } from '../core/firebase.errors';
import {AlertService} from '../@lib/services/alert.service';



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private user: firebase.User;
  deactived: AngularFireObject<any>;
  tutorials: Observable<any[]>;

  constructor(
    private http: HttpClient,
    private afData: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    private router: Router,
    public userService: UserService,
    public alertService: AlertService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      new User({
        'sTokenManager.accessToken': localStorage.getItem('ct'),
        expirationTime: parseInt(localStorage.getItem('ex'))
      })
    );
    this.currentUser = this.currentUserSubject.asObservable();

  }

  getUser(): Promise<any> {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async getRolestypes(): Promise<any> {

    return new Promise((resolve, reject) => {

      this.afAuth.authState.subscribe(async (user) => {

        const diff5Min = this.currentUserValue.expirationTime - 300000;

        if (diff5Min < Date.now() && diff5Min !== -300000) {
          // Atualizar o refresh Token
          firebase.auth().currentUser.getIdToken(true).then(
            jwt => {
              // const currentdate = new Date();
              // const now = currentdate.getDate() + '/'
              //   + (currentdate.getMonth() + 1) + '/'
              //   + currentdate.getFullYear() + ' @ '
              //   + currentdate.getHours() + ':'
              //   + currentdate.getMinutes() + ':'
              //   + currentdate.getSeconds();

              // console.log(this.currentUserValue);
              // console.log('Refresh Token |', now);
              // console.log('Refresh Token |', jwt);
              // console.log(this.currentUserValue);

              localStorage.setItem('ct', jwt);
              localStorage.setItem('ex', Date.now().toString());
              this.currentUserValue.patchValue(this.currentUserValue);
              this.setCurrentUser(this.currentUserValue);
            }
          );
        }

        // Se o usuário tiver autenticado
        if (user) {
          this.user = user;

          // Salva em memória os dados do usuário
          this.setCurrentUser(new User(user.toJSON()));

          // Salva os tokens em localstorage
          localStorage.setItem('ct', this.currentUserValue.accessToken);
          localStorage.setItem('uid', this.currentUserValue.firebase_uid);

          // Monitorar se o usuário continua ativo
          this.checkUserDeactivated();
          if (this.currentUserValue.accessToken) {

            this.userService.getPermission().subscribe(
              data => {
                data.patchValue(this.currentUserValue);
                this.setCurrentUser(data);
                resolve(this.currentUserValue);
              }
            );

            // alert(router.routerState.snapshot.url);
            // this.router.navigate(['/dashboard/main']);
          }
        } else {
          this.logout().then(() => {
            reject('not logged');
            this.router.navigate(['/authentication/signin']);
          });
        }
      });
    });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  checkUserDeactivated() {
    // Verificar se o usuário está desativado
    this.afData.object(`deactivation_list/${this.currentUserValue.firebase_uid}`).valueChanges().subscribe(
      data => {
        if (data) {
          // {code: 'auth/user-disabled', message: 'The user account has been disabled by an administrator.', a: null}
          this.logout().then(() => {
            this.router.navigate(['/authentication/signin']);
            Swal.fire('A conta do usuário foi desabilitada por um administrador.', '', 'error');
          });
        }


      }
    );
  }

  async setDeactivation(uid: string, userId: string): Promise<Observable<string>> {
    const userRef = this.afData.object(`deactivation_list/${uid}`);
    // Atualiza no firebase
    await userRef.set({ deactivation_date: new Date().toLocaleString() });
    // Atualiza no API
    return this.userService.updateStatus(userId, 'false');
  }

  async setActivation(uid: string, userId: string): Promise<Observable<string>> {
    const userRef = this.afData.object(`deactivation_list/${uid}`);
    // Atualiza no firebase
    await userRef.remove();
    // Atualiza no API
    return this.userService.updateStatus(userId, 'true');
  }


  setCurrentUser(user) {
    this.currentUserSubject.next(user);
  }

  updateEmail(value) {
    return this.user.updateEmail(value);
  }

  updateProfile(value) {
    return this.user.updateProfile({
      displayName: value.displayName,
      photoURL: value.photoURL
    });
  }

  async updatePhoneNumber(phoneNumber) {
    const appVerifier = new firebase.auth.RecaptchaVerifier('save-user-button', {
      size: 'invisible', callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        alert(response);
      }
    }); // An element with id="save-user-button" must exist
    const authProvider = new firebase.auth.PhoneAuthProvider();
    const verificationId = await authProvider.verifyPhoneNumber(phoneNumber, appVerifier);
    const verificationCode = window.prompt('Insira o código de verificação que foi enviado para o seu telefone.');
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    return this.user.updatePhoneNumber(phoneCredential);
  }

  async changePassword(oldPassword: string, newPassword: string, newPasswordConfirm: string): Promise<boolean> {
    let saveResult = true;
    const cpUser = firebase.auth().currentUser;

    const credentials = firebase.auth.EmailAuthProvider.credential(
      cpUser.email, oldPassword);

    await cpUser.reauthenticateWithCredential(credentials).then(
      success => {
        if (newPassword !== newPasswordConfirm) {
          this.alertService.error('Nova senha e Confirmação de senhas, estão diferentes');
          saveResult = false;
        } else if (newPassword.length < 6) {
          this.alertService.error('Requer no mínimo 6 caracteres!');
          saveResult = false;
        } else {
          this.alertService.success('Senha atualizada com sucesso!');
          cpUser.updatePassword(newPassword).then(function() {
          }).catch(function(error) {
            const errorMessage = FirebaseErrors.Parse(error.code);
            this.alertService.success(error);
          });
          saveResult = false;
        }
      },
      error => {
        if (error.code === 'auth/wrong-password') {
          this.alertService.error('Senha atual está invalida');
          saveResult = false;
        } else {
          const errorMessage = FirebaseErrors.Parse(error.code);
          this.alertService.error(errorMessage);
          saveResult = false;
        }
      }
    );
    return saveResult;
  }

  signInWithEmail(credentials) {
    // return firebase
    //   .auth()
    //   .signInWithEmailAndPassword(credentials.email, credentials.password);
    return this.afAuth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  signUp(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  async createUser(credentials) {

    const app2 = firebase.initializeApp(environment.firebaseConfig, 'tempApp');

    const success = await app2.auth().createUserWithEmailAndPassword(
      credentials.email,
      credentials.password
    );

    await app2.auth().signOut();
    await app2.delete()
      .then(function() {
        // console.log("Instância do firebase de criaçao do usuário removida com sucesso!");
      })
      .catch(function(error) {
        const errorMessage = FirebaseErrors.Parse(error.code);
        Swal.fire(errorMessage, '', 'error');
      });

    return success;
  }

  get authenticated(): boolean {
    return this.user !== null;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    return (this.currentUserValue.emailVerified !== false) ? true : false;
  }

  getEmail() {
    return this.user && this.user.email;
  }

  logout(): Promise<void> {
    localStorage.removeItem('ct');
    this.currentUserSubject.next(null);
    // return of({ success: false });
    return this.afAuth.signOut();
  }

  // Email verification when new user register
  sendVerificationMail() {
    return firebase.auth().currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email']);
      }).catch((error) => {
        const errorMessage = FirebaseErrors.Parse(error.code);
        Swal.fire(errorMessage, '', 'error');
      });
  }

  passwordRecover(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        Swal.fire('O e-mail de redefinição de senha foi enviado, verifique sua caixa de entrada.', '', 'success');
      }).catch((error) => {
        const errorMessage = FirebaseErrors.Parse(error.code);
        Swal.fire(errorMessage, '', 'error');
      });
  }

  uploadImage(image, uid, userId) {
    // image = normalizeURL(image);

    // const ref = this.afStorage.ref(`/profiles/${uid}`);
    // const task = ref.put(image.target.files[0]);
    // const uploadProgress = task.percentageChanges();
    // console.log(task.percentageChanges());

    // const downloadURL = task.then(data => {
    //   console.log(data.ref.getDownloadURL());

    // }); // downloadURL();


    this.afStorage.upload(`/profiles/${uid}`, image.target.files[0]).then(
      data => {
        data.ref.getDownloadURL().then(
          url => {
            this.userService.updatePhoto(userId, url).subscribe(
              () => {
                this.updateProfile({
                  displayName: this.currentUserValue.displayName,
                  photoURL: url
                }).then();
                // Swal.fire('Foto atualizada com sucesso!', '', 'success');
                window.location.reload();
              }
            );
          }
        );
      }
    );
  }

  async getPermissionByModuleName(moduleNameArray) {
    if (moduleNameArray.length > 1) {
      moduleNameArray.splice(0, 1);
    }
    const found = await this.currentUserValue.userPermissions.find(elem => moduleNameArray.includes(elem.permission.moduleName));
    return found;
  }

  // ----------------------------------------------------------------

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.API}/authenticate`, {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  // logout() {
  //   // remove user from local storage to log user out
  //   localStorage.removeItem('currentUser');
  //   this.currentUserSubject.next(null);
  //   return of({ success: false });
  // }
}
