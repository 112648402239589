import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-locale-dialog',
  templateUrl: './locale-dialog.component.html',
  styleUrls: ['./locale-dialog.component.scss']
})
export class LocaleDialogComponent implements OnInit {
  panelOpenState = false;
  list = laocale;
  constructor(private route: Router) { }

  goRoute(id) {
    this.route.navigate([`producer/attendance/${id}/details`]);
  }

  ngOnInit(): void {
  }

}
const laocale = [
  {name: 'Nova Lima - MG', address: 'Rua Doze N°28, Bairro Novo - Nova Lima - MG, CEP 34004025'},
  {name: 'Belo Horizonte - MG', address: 'Rua Doze N°28, Bairro Novo - Belo Horizonte - MG, CEP 34004025'},
  {name: 'São Paulo - SP', address: 'Rua Doze N°28, Bairro Novo - São Paulo - SP, CEP 34004025'},
  {name: 'Rio de Janeiro - RJ', address: 'Rua Doze N°28, Bairro Novo - Rio de Janeiro - RJ, CEP 34004025'},
  {name: 'Campo Grande - MS', address: 'Rua Doze N°28, Bairro Novo - Campo Grande - MS, CEP 34004025'},
];
