import { addDays, format, parse, startOfDay } from 'date-fns';
import { PLACETYPE } from '../config/config';
import * as moment from 'moment-timezone';

export function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export function getPlaceTypeIdByName(placeType: string): string {
  const listOb = Object.assign(PLACETYPE);
  const listNames = Object.keys(PLACETYPE);

  // tslint:disable-next-line: prefer-for-of
  for (let index = 0; index < listNames.length; index++) {
    const element = listOb[listNames[index]];

    if (element.title.toLocaleLowerCase() === placeType.toLocaleLowerCase()) {
      return element.id;
    }
  }
}

export abstract class DateUtils {
  public static getDaysBetweenDates(
    startDate: string,
    endDate: string,
  ): string[] {
    const start = parse(startDate, 'yyyy-MM-dd', new Date());
    const end = parse(endDate, 'yyyy-MM-dd', new Date());
    let tmpDate = startOfDay(start);
    const days = [];
    while (tmpDate <= end) {
      days.push(format(tmpDate, 'yyyy-MM-dd'));
      tmpDate = addDays(tmpDate, 1);
    }
    return days;
  }

  /**
   * @param type + ou -, se deseja adicionar ou subtrair dias
   * @param numberDays Número de dias a ser adicionado ou subtraido
   * @returns Retorna o dia desejado
   * @example getDayWithAddSub('+',7), irá retornar a data com + 7 dias para frente
   */
  public static getDayWithAddSub(type, numberDays): Date {
    const today = new Date();
    if (type === '+') {
      today.setDate(today.getDate() + numberDays);
      return today;
    } else {
      today.setDate(today.getDate() - numberDays);
      return today;
    }
  }

  /**
   * @param type + ou -, se deseja adicionar ou subtrair dias
   * @param numberDays Número de dias a ser adicionado ou subtraido
   * @returns Retorna o dia desejado em String
   */
  public static getDayWithAddSubString(type, numberDays): string {
    return this.getDayWithAddSub(type, numberDays).toISOString().split('T')[0];
  }

  public static get firstDayMonth(): string {
    const today = new Date();
    return (
      today.getFullYear() +
      '-' +
      String(today.getMonth() + 1).padStart(2, '0') +
      '-01'
    );
  }

  public static get lastDayMonth(): string {
    const lastDayOfMonth = new Date(DateUtils.today.getFullYear(), DateUtils.today.getMonth() + 1, 0);
    return DateUtils.toDateString(lastDayOfMonth);
  }

  public static get todayString(): string {
    return new Date().toISOString().split('T')[0];
  }

  public static get today(): Date {
    return new Date();
  }

  public static get tomorrowString(): string {
    return DateUtils.getDayWithAddSub('+', 1).toISOString().split('T')[0];
  }

  public static get tomorrow(): Date {
    return DateUtils.getDayWithAddSub('+', 1);
  }

  public static get yesterdayString(): string {
    return DateUtils.getDayWithAddSub('-', 1).toISOString().split('T')[0];
  }

  public static get yesterday(): Date {
    return DateUtils.getDayWithAddSub('-', 1);
  }

  public static get last3Days(): string {
    return DateUtils.getDayWithAddSub('-', 3).toISOString().split('T')[0];
  }

  public static get last15Days(): string {
    return DateUtils.getDayWithAddSub('-', 15).toISOString().split('T')[0];
  }

  public static get last30Days(): string {
    return DateUtils.getDayWithAddSub('-', 30).toISOString().split('T')[0];
  }

  public static get last60Days(): string {
    return DateUtils.getDayWithAddSub('-', 60).toISOString().split('T')[0];
  }

  public static get last90Days(): string {
    return DateUtils.getDayWithAddSub('-', 90).toISOString().split('T')[0];
  }

  public static getYear(date): string {
    return moment(date).tz('America/Sao_Paulo').format('YYYY');
  }

  public static getMonth(date): string {
    const x = moment(date).tz('America/Sao_Paulo').format('MM');
    switch (x) {
      case '01':
        return 'Janeiro';
      case '02':
        return 'Fevereiro';
      case '03':
        return 'Março';
      case '04':
        return 'Abril';
      case '05':
        return 'Maio';
      case '06':
        return 'Junho';
      case '07':
        return 'Julho';
      case '08':
        return 'Agosto';
      case '09':
        return 'Setembro';
      case '10':
        return 'Outubro';
      case '11':
        return 'Novembro';
      case '12':
        return 'Dezembro';
    }
    return;
  }

  public static toString(date): string {
    return moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss');
  }

  public static toDateString(date): string {
    return moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DD');
  }

  public static toBRDateString(date): string {
    return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY');
  }

  public static toDate(date): Date {
    return moment(date).tz('America/Sao_Paulo').toDate();
  }

  public static formatDate(date): string {
    return moment(date).format('DD-MM-YYYY');
  }
}
