<section class="content">
    <div class="container-fluid">
        <div>
            <!-- Titulo -->
            <h1>Relatório Extrato de Movimentos</h1>
            <br>
            <!--Conteudo -->
            <div>
                <div class="row">
                    <div class="col-sm-12">
                        <br>
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Filtrar
                                    </mat-panel-title>
                                    <mat-panel-description>
                                         
                                        <mat-icon>search</mat-icon>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="expansion-panel-m-2">
                                    <form [formGroup]="filter" (ngSubmit)="onFilter()" class="row">
                                        <div class="col-sm-6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Data início do Movimento</mat-label>
                                                <input matInput [matDatepicker]="dateStart" formControlName="dateStart">
                                                <mat-datepicker-toggle matSuffix [for]="dateStart">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #dateStart></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Data Fim do Movimento</mat-label>
                                                <input matInput [matDatepicker]="dateEnd" formControlName="dateEnd">
                                                <mat-datepicker-toggle matSuffix [for]="dateEnd">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #dateEnd></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <app-lookup [service]="typeRtiService" [fieldName]="'name'" [title]="'RTIs'"
                                                (optionSelected)="getRTISelected($event)" clearAndBlurAfterSelect>
                                            </app-lookup>
                                        </div>
                                        <div class="col-sm-4">
                                            <app-lookup [service]="movementTypeService" [fieldName]="'name'"
                                                [title]="'Tipo Movimento'"
                                                (optionSelected)="getMovementTypesSelected($event)"
                                                clearAndBlurAfterSelect>
                                            </app-lookup>
                                        </div>
                                        <div class="col-sm-4">
                                            <app-lookup [service]="movementStepService" [fieldName]="'name'"
                                                [title]="'Etapa Movimento'"
                                                (optionSelected)="getMovementStepsSelected($event)"
                                                clearAndBlurAfterSelect>
                                            </app-lookup>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field appearance="outline" *ngIf="list.rtiTypes.length > 0"
                                                style="width: 100%;">
                                                <mat-label>RTIs</mat-label>
                                                <mat-chip-list #chipList aria-label="Seleção de RTIs">
                                                    <mat-chip *ngFor="let rtiType of list.rtiTypes" selectable="true"
                                                        removable="true" (removed)="removeItem(rtiType, 'rtiTypes')">
                                                        {{rtiType.name}}
                                                        <button matChipRemove>
                                                            <mat-icon>cancel</mat-icon>
                                                        </button>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field appearance="outline" *ngIf="list.movementTypes.length > 0"
                                                style="width: 100%;">
                                                <mat-label>Tipos Movimentos</mat-label>
                                                <mat-chip-list #chipList aria-label="Seleção de Tipos Movimentos">
                                                    <mat-chip *ngFor="let movementType of list.movementTypes"
                                                        selectable="true" removable="true"
                                                        (removed)="removeItem(movementType, 'movementTypes')">
                                                        {{movementType.name}}
                                                        <button matChipRemove>
                                                            <mat-icon>cancel</mat-icon>
                                                        </button>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field appearance="outline" *ngIf="list.movementSteps.length > 0"
                                                style="width: 100%;">
                                                <mat-label>Etapas Movimentos</mat-label>
                                                <mat-chip-list #chipList aria-label="Seleção de Etapas Movimentos">
                                                    <mat-chip *ngFor="let movementStep of list.movementSteps"
                                                        selectable="true" removable="true"
                                                        (removed)="removeItem(movementStep, 'movementSteps')">
                                                        {{movementStep.name}}
                                                        <button matChipRemove>
                                                            <mat-icon>cancel</mat-icon>
                                                        </button>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-2">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Tipo do Conta</mat-label>
                                                <mat-select id="type"
                                                    (selectionChange)="getTypeAccountSelected($event.value)">
                                                    <mat-option *ngFor="let select of accountTypeList"
                                                        [value]="select.key">
                                                        {{select.value}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3" *ngIf="viewAccounts">
                                            <app-lookup [service]="accountService" [fieldName]="'tradingName'"
                                                [title]="'Contas'" (optionSelected)="getAccountSelected($event)"
                                                isServerSide [filterPagination]="accountFilterPagination"
                                                autoSelectById>
                                            </app-lookup>
                                        </div>
                                        <div class="col-sm-7">
                                            <mat-form-field appearance="outline" class="w-100" *ngIf="viewPlaces">
                                                <mat-label>Locais</mat-label>
                                                <input type="text" placeholder="Selecione um local" aria-label="Number"
                                                    matInput [formControl]="myControl" [matAutocomplete]="auto"
                                                    #placeInput>
                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                                    (optionSelected)="getPlacesDestinyTypeSelected(myControl.value, placeInput)">
                                                    <mat-option *ngFor="let option of filteredOptions | async"
                                                        [value]="option">
                                                        {{option.tradingName}} | {{option.companyName}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-5"></div>
                                        <div class="col-sm-7">
                                            <mat-form-field appearance="outline" *ngIf="list.places.length > 0"
                                                style="width: 100%;">
                                                <mat-label>Locais</mat-label>
                                                <mat-chip-list #chipList aria-label="Seleção de Locais">
                                                    <mat-chip *ngFor="let place of list.places" selectable="true"
                                                        removable="true" (removed)="removeItem(place, 'places')">
                                                        {{place.tradingName}}
                                                        <button matChipRemove>
                                                            <mat-icon>cancel</mat-icon>
                                                        </button>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-12">
                                            <button class="btn btn-primary float-right" type="submit" id="filter"
                                                [disabled]="!validForm">
                                                <i class="ti-filter" aria-hidden="true"></i>
                                                Pesquisar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <br>
                <div class="card">
                    <div class="body">
                        <app-export [rows]="report" [fileName]="'report-extract-movement'"></app-export>

                        <ngx-datatable class="material" [rowHeight]="'auto'" [columnMode]="'force'" [reorderable]="true"
                            [headerHeight]="height" [footerHeight]="height" [externalPaging]="paging"
                            [loadingIndicator]="loading" [messages]="messages" [rows]="rows" [offset]="offset"
                            [count]="pagination.total" [limit]="pagination.total" (page)="onPage($event)"
                            (sort)="onSort($event)" [scrollbarH]="true">
                            <ngx-datatable-column [resizeable]="true" name="Ano" prop="year"></ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Mês" prop="month">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Data" prop="date">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{formatDate(row.date)}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Guia" prop="guide_number" >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <a (click)="goRoute(row.id)" style="cursor: pointer;">{{row.guide_number}}</a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Transação" prop="transaction">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="LOCAL Origem" prop="origin">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="LOCAL Destino" prop="destination">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="LOCAL Filtro" prop="filterPart">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="CONTRAPARTIDA" prop="counterPart">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Tipo Movimento" prop="movementType">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="RTI" prop="rtiType">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Nota Fiscal" prop="nfeNumber">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Estado" prop="rtiStatus">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Quantidade" prop="quantity">
                            </ngx-datatable-column>
                            <ngx-datatable-column [resizeable]="true" name="Etapa Atual" prop="movementStep">
                            </ngx-datatable-column>
                        </ngx-datatable>

                    </div>
                </div>
            </div>
        </div>
    </div>

</section>