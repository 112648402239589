import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guard/auth.guard';
import {MovimentsComponent} from './moviments/moviments/moviments.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard/main', pathMatch: 'full' },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: 'producer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./producer/producer.module').then((m) => m.ProducerModule)
  },
  {
    path: 'retailer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./retailer/retailer.module').then((m) => m.RetailerModule)
  },
  {
    path: 'moviments',
    canActivate: [AuthGuard],
    loadChildren: () => import('./moviments/moviments.module').then((m) => m.MovimentsModule)
  },
  {
    path: 'movements-administration',
    canActivate: [AuthGuard],
    loadChildren: () => import('./movements-admnistration/movements-admnistration.module').then((m) => m.MovementsAdmnistrationModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsModule)
  },
  {
    path: 'registrations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./registrations/registrations.module').then((m) => m.RegistrationsModule)
  },
  {
    path: 'permissions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./permissions/permissions.module').then((m) => m.PermissionsModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule)
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    loadChildren: () => import('./payments/payments.module').then((m) => m.PaymentsModule)
  },
  {
    path: 'stocks',
    canActivate: [AuthGuard],
    loadChildren: () => import('./stocks/stocks.module').then((m) => m.StocksModule)
  },
  {
    path: 'revenues',
    canActivate: [AuthGuard],
    loadChildren: () => import('./revenues/revenues.module').then((m) => m.RevenuesModule)
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
