import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: DialogData
	) {
	}

	ngOnInit() {
	}

	public decline() {
		this.dialogRef.close(false);
	}

	public accept() {
		this.dialogRef.close(true);
	}
}

export interface DialogData {
	title: string;
	message: string;
	btnOkText: string;
	btnCancelText: string;
}
