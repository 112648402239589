import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExportService } from 'src/app/service/export.service';
import { ExportType, ExportTypeEnum } from './enum/export-enum';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  exportTypes = ExportTypeEnum.getTypes();
  // tslint:disable-next-line: variable-name
  _rows: any[] = [];

  @Input() set rows(rows: any[]) {
    if (rows) {
      this._rows = rows;
    }
  }

  @Input() fileName = '';
  @Input() showImport = false;

  @Output() fileReaded: EventEmitter<any> = new EventEmitter();

  constructor(protected exportService: ExportService) { }

  ngOnInit(): void {
    if (this.showImport !== false) {
      this.showImport = true;
    }
  }

  exportExcel(): void {
    this.exportService.exportAsExcelFile(this._rows, this.fileName);
  }

  async importFile($event, type: ExportType = ExportType.JSON) {
    this.fileReaded.emit(await this.exportService.onFileChange($event, type));
  }

}
