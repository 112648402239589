import { Injectable } from '@angular/core';
import {BaseProviderService} from './base-provider.service';
import {ILibFormService, ILibTableService} from '../@lib/services/contracts.services';
import {TypesMovementFilter, TypesMovementSearch} from '../models/filters.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FilterPagination, PaginatedList} from '../@lib/models/commons.models';
import {TypesMovement} from '../models/types-movement.model';

@Injectable({
  providedIn: 'root'
})
export class MovementTypeService extends BaseProviderService implements ILibTableService<TypesMovement, TypesMovementFilter, TypesMovementSearch>, ILibFormService<TypesMovement> {
  protected module = 'movement-type';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): TypesMovement {
    return new TypesMovement();
  }

  get(id: string): Observable<TypesMovement> {
    return this.http.get<TypesMovement>(this.getUrl(this.module, [id]))
      .pipe(map(x => new TypesMovement(x)));
  }

  list(): Observable<PaginatedList<TypesMovement>> {
    return this.http.get(this.getUrl(this.module, ['all']))
      .pipe(map(d => new PaginatedList<TypesMovement>(TypesMovement, d)));
  }

  filter(filter: FilterPagination<TypesMovementFilter, TypesMovementSearch>): Observable<PaginatedList<TypesMovement>> {
    return this.http.post<PaginatedList<TypesMovement>>(this.getUrl(this.module, ['filter']), filter)
      .pipe(map(d => new PaginatedList<TypesMovement>(TypesMovement, d)));
  }

  update(model: TypesMovement): Observable<TypesMovement> {
    return this.http.patch<TypesMovement>(this.getUrl(this.module, [model.id]), model).pipe(map(x => new TypesMovement(x)));
  }
  updateStatus(id: string, status: boolean): Observable<string> {
    const statusString = (status === true) ? 'true' : 'false';
    return this.http.patch<string>(this.getUrl(this.module, ['status', id, statusString]), null);
  }
  save(model: TypesMovement): Observable<TypesMovement> {
    delete model.id;
    return this.http.post<TypesMovement>(this.getUrl(this.module), model).pipe(map(x => new TypesMovement(x)));
  }

  delete(id: number): Observable<TypesMovement> {
    return this.http.delete<TypesMovement>(this.getUrl(this.module, [id]))
      .pipe(map(x => new TypesMovement(x)));
  }

}
