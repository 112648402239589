import { AlertService } from 'src/app/@lib/services/alert.service';
import { AuthService } from '../../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private router: Router, private alertService: AlertService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          // location.reload();
        } else if (err.status === 403) {
          // auto logout if 401 response returned from api
          this.router.navigate(['/authentication/forbidden']);
          //  location.reload();
        } else if (err.status === 0) {
          this.alertService.dialogConfirm('A API está offline, aguarde alguns instantes!');
          this.router.navigate(['/authentication/page500']);
          //  location.reload();
        }


        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
