import { NgControl } from '@angular/forms';
import { Directive, Input } from "@angular/core";

@Directive({
    selector: '[disableControl]'
})
export class DisableControlDirective {

    @Input()
    set disableControl(condition : boolean) {
        if (this.ngControl.control != null) {
            if (condition)
                this.ngControl.control.disable({emitEvent: false, onlySelf: false});
            else
                this.ngControl.control.enable({emitEvent: false, onlySelf: false});
        }
    }

    constructor( private ngControl : NgControl ) { }
}
