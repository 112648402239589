export function Required(target: object, propertyKey: string) {
    Object.defineProperty(target, propertyKey, {
        get() {
            console.log(target);

            throw new Error(`O Atributo "${propertyKey}" é obrigatório`);
        },
        set(value) {
            Object.defineProperty(this, propertyKey, {
                value,
                writable: true,
                configurable: true,
            });
        },
    });
}
