<h2 mat-dialog-title>Varejista - Locais</h2>
<mat-dialog-content class="mat-typography">
  <mat-accordion>
    <mat-expansion-panel hideToggle *ngFor="let l of list">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{l.name}}
        </mat-panel-title>
        <mat-panel-description>
          Type your name and age
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="container">
        <p>{{l.address}}</p>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn float-right" mat-button [mat-dialog-close]="true"><i class="ti-close"></i> Fechar</button>
</mat-dialog-actions>
