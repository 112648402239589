import { SortType } from '../enums/commons.enum';
import {PaginatedList} from './commons.models';
import {IModel} from './contracts.models';

/**
 * Classe de paginação da tabela LIB
 */
export class LibTablePagination {
    pageCount = 0;
    total = 0;
    sortField = '';
    pageNumber = 1;
    skip = 0;
    take = 20;
    sortOrder: SortType = SortType.Ascending;

    /**
     * Construtor da paginação da tabela LIB
     * @param pageNumber Página inicial
     * @param pageSize Quantidade de itens por página
     */
    constructor(pageNumber: number = 1, take: number = 20, skip: number = 0) {
        this.pageNumber = pageNumber;
        this.take = take;
        this.skip = skip;
    }

    sort(field: string, order: string) {
        this.sortOrder = (order === 'desc') ? SortType.Descending : SortType.Ascending;
        this.sortField = field == '' ? null : field;
    }

    update(list: PaginatedList<IModel>) {
        this.pageNumber = list.currentPage;
        this.skip = list.skip;
        this.total = list.total;
        this.take = list.take;
    }
}


/**
 * Classe de mensagens da tabela LIB
 */
export class LibTableMessage {
    public emptyMessage: string;
    public totalMessage: string;

    /**
     * Construtor de mensagens da tabela LIB
     * @param other Array das mensagens necessárias para a tabela ou objeto com os campos de mensagens necessários para a tabela
     */
    constructor(other: any) {
        if (other != null) {
            if (other instanceof Array) {
                this.emptyMessage = other.length > 0 ? other[0] : '';
                this.totalMessage = other.length > 1 ? other[1] : '';
            } else {
                this.emptyMessage = other.emptyMessage || '';
                this.totalMessage = other.totalMessage || '';
            }
        }
    }
}
