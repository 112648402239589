<div class="row">
    <div class="col-sm-12">
        <button class="btn btn-success float-right" type="button" (click)="exportExcel()"
            matTooltip="Exportar Excel" title="Exportar Excel" [disabled]="_rows.length===0">
            <i aria-hidden="true"></i><img src="assets/images/icons/excel.svg">
        </button>
        <input type="file" (change)="importFile($event, exportType.value)" multiple="false" *ngIf="showImport" />
        <mat-form-field appearance="outline"  [hidden]="!showImport">
            <mat-label>Tipos</mat-label>
            <mat-select #exportType>
              <mat-option *ngFor="let select of exportTypes" [value]="select.key">
                {{select.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
    </div>
</div>