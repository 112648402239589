// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'Dev',
  API: 'http://hb-dev.hb-smr.com.br:3000',
  // API: 'http://localhost:3000',
  WEB: 'http://hb-dev.tolentinosserver.com',
  firebaseConfig: {
    apiKey: 'AIzaSyANcAaD3Jqq-2qIkQWnl03E4mbekyq07oA',
    authDomain: 'hb20-d6220.firebaseapp.com',
    projectId: 'hb20-d6220',
    storageBucket: 'hb20-d6220.appspot.com',
    messagingSenderId: '414709331704',
    appId: '1:414709331704:web:cfbd7034f814fe2d62ed0d',
    measurementId: 'G-Y29GCZ5356'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
