import { Injectable } from '@angular/core';
import { BaseProviderService } from './base-provider.service';
import { ILibFormService, ILibListService, ILibTableService } from '../@lib/services/contracts.services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterPagination, PaginatedList } from '../@lib/models/commons.models';
import { MovementStep } from '../models/movement-step.model';
import { MovementStepFilter, MovementStepSearch } from '../models/filters.model';
import { RegimeInContract } from '../models/regime-in-contract.model';

@Injectable({
  providedIn: 'root'
})
export class MovementStepService extends BaseProviderService implements ILibTableService<MovementStep, MovementStepFilter, MovementStepSearch>, ILibListService<MovementStep> {
  protected module = 'movement-step';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): MovementStep {
    return new MovementStep();
  }

  get(id: string): Observable<MovementStep> {
    return this.http.get<MovementStep>(this.getUrl(this.module, [id]))
      .pipe(map(x => new MovementStep(x)));
  }

  getPaginated(id: string): Observable<PaginatedList<MovementStep>> {
    return null;
  }

  list(): Observable<any> {
    const params = new HttpParams().set('sortField', 'order');

    return this.http.get(this.getUrl(this.module, ['all']), { params })
      .pipe(map(d => new PaginatedList<MovementStep>(MovementStep, d)));
  }

  filter(filter: FilterPagination<MovementStepFilter, MovementStepSearch>): Observable<PaginatedList<MovementStep>> {
    return this.http.post<PaginatedList<MovementStep>>(this.getUrl(this.module, ['filter']), filter)
      .pipe(map(d => new PaginatedList<MovementStep>(MovementStep, d)));
  }

  update(model: MovementStep): Observable<MovementStep> {
    return this.http.patch<MovementStep>(this.getUrl(this.module, [model.id]), model).pipe(map(x => new MovementStep(x)));
  }

  updateStatus(id: string, status: boolean): Observable<string> {
    const statusString = (status === true) ? 'true' : 'false';
    return this.http.patch<string>(this.getUrl(this.module, ['status', id, statusString]), null);
  }

  save(model: MovementStep): Observable<MovementStep> {
    delete model.id;
    return this.http.post<MovementStep>(this.getUrl(this.module), model).pipe(map(x => new MovementStep(x)));
  }

  delete(id: number): Observable<MovementStep> {
    return this.http.delete<MovementStep>(this.getUrl(this.module, [id]))
      .pipe(map(x => new MovementStep(x)));
  }

}
