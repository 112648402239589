<h2 mat-dialog-title>Tipo de local</h2>
<div>
  <div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nome</mat-label>
      <input matInput placeholder="Ex. Local 13">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Descrição</mat-label>
      <input matInput placeholder="Ex. Local definido...">
    </mat-form-field>
  </div>
</div>
<mat-dialog-actions>
  <div style="float: right">
    <button mat-button mat-dialog-close><i class="ti-close"></i> Cancelar</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial><i class="ti-save"></i> Salvar</button>
  </div>
</mat-dialog-actions>
