import { Injectable } from '@angular/core';
import { BaseProviderService } from './base-provider.service';
import { ILibFormService, ILibTableService } from '../@lib/services/contracts.services';
import { PlacesFilter, PlacesSearch } from '../models/filters.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterPagination, PaginatedList } from '../@lib/models/commons.models';
import { Places } from '../models/places.model';
import {Product} from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class PlacesService extends BaseProviderService implements ILibTableService<Places, PlacesFilter, PlacesSearch>, ILibFormService<Places> {

  protected module = 'places';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): Places {
    return new Places();
  }

  get(id: string): Observable<Places> {
    return this.http.get<Places>(this.getUrl(this.module, [id]))
      .pipe(map(x => new Places(x)));
  }

  getPaginated(id: string): Observable<any> {
    return this.http.get<PaginatedList<Places>>(this.getUrl(this.module, [id]))
      .pipe(map(d => new PaginatedList<Places>(Places, d)));
  }

  list(): Observable<any> {
    return this.http.get(this.getUrl(this.module, ['all']))
      .pipe(map(d => new PaginatedList<Places>(Places, d)));
  }

  filter(filter: FilterPagination<PlacesFilter, PlacesSearch>): Observable<PaginatedList<Places>> {
    return this.http.post<PaginatedList<Places>>(this.getUrl(this.module, ['filter']), filter)
      .pipe(map(d => new PaginatedList<Places>(Places, d)));
  }

  update(model: Places): Observable<Places> {
    return this.http.patch<Places>(this.getUrl(this.module, [model.id]), model).pipe(map(x => new Places(x)));
  }

  updateStatus(id: string, status: boolean): Observable<string> {
    const statusString = (status === true) ? 'true' : 'false';
    return this.http.patch<string>(this.getUrl('places', ['status', id, statusString]), null);
  }

  save(model: Places): Observable<Places> {
    delete model.id;
    return this.http.post<Places>(this.getUrl(this.module), model).pipe(map(x => new Places(x)));
  }

  submitPlaces(id, list): Observable<any> {
    return this.http.post<any>(this.getUrl(this.module, [id, 'places']), list);
  }

  delete(id: number): Observable<Places> {
    return this.http.delete<Places>(this.getUrl(this.module, [id]))
      .pipe(map(x => new Places(x)));
  }

}
