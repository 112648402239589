import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { AlertService } from '../@lib/services/alert.service';
import { read, utils, WorkBook, WorkSheet, write, writeFileXLSX } from 'xlsx';
// https://www.npmjs.com/package/xlsx
// https://github.com/SheetJS/sheetjs/blob/61b17a8bc857dc7d20dd3ec1248024a5c45c6e38/demos/angular2/src/app/sheetjs.component.ts

import { saveAs } from 'file-saver';
import { ExportType, ExportTypeEnum } from '../components/export/enum/export-enum';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

type AOA = any[][];

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    constructor(protected alertService: AlertService, ) { }

    onFileChange(evt: any, type: ExportType = ExportType.JSON): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            /* wire up file reader */
            const target: DataTransfer = (evt.target) as DataTransfer;
            if (target.files.length !== 1) { throw new Error('Não é possível usar vários arquivos!'); }
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const ab: ArrayBuffer = e.target.result;
                const wb: WorkBook = read(ab);

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: WorkSheet = wb.Sheets[wsname];

                switch (type) {
                    case ExportType.AOA:
                        resolve((utils.sheet_to_json(ws, { header: 1 })) as AOA);
                    case ExportType.CSV:
                        resolve(utils.sheet_to_csv(ws));
                    case ExportType.HTML:
                        resolve(utils.sheet_to_html(ws));
                    case ExportType.TXT:
                        resolve(utils.sheet_to_txt(ws));
                    case ExportType.JSON:
                        resolve(utils.sheet_to_json(ws));
                    default:
                        reject(null);
                }
                // console.log((utils.sheet_to_json(ws, { header: 1 })) as AOA); // Carregar estilo array de array
                // console.log(utils.sheet_to_csv(ws));
                // console.log(utils.sheet_to_html(ws));
                // console.log(utils.sheet_to_txt(ws));
                // console.log(utils.sheet_to_json(ws)); // Carregar estilo json normal

            };
            reader.readAsArrayBuffer(target.files[0]);
        });
    }

    public exportAsExcelFile(rows: any[], excelFileName: string): void {
        if (rows.length > 0) {
            rows = this.removeFieldWithNameId(rows);
            const worksheet: WorkSheet = utils.json_to_sheet(rows);
            const workbook: WorkBook = { Sheets: { 'Compte-rendu': worksheet }, SheetNames: ['Compte-rendu'] };
            const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, excelFileName);
        } else {
            this.alertService.message('Sem linhas para exportar...');
        }
    }
    private saveAsExcelFile(buffer: any, baseFileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs(data, baseFileName + '_' + this.getDateFormat(new Date()) + EXCEL_EXTENSION);
    }

    private getDateFormat(date: Date): string {
        return formatDate(date, 'yyyyMMdd_HHmmss', 'en-US');
    }

    removeFieldWithNameId(rows: any[]): any[] {
        for (const r of rows) {
            // Retorna a lista de nomes dos campos pela pesquisa
            const keys = Object.keys(r).filter(elem => elem.toLowerCase().includes('id'));

            // remove do objeto todos os itens com as keys encontradas
            for (const k of keys) {
                delete r[k];
            }
        }

        return rows;
    }
}
