<form class="w-100" [class.bg-oculto]="isValid">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>{{title}}</mat-label>
    <input type="text"
           placeholder="Digite para pesquisar"
           aria-label="Number"
           matInput
           #input
           [formControl]="myControl"
           [readonly]="activeReadonly"
           [matAutocomplete]="auto" (keyup)="keyUp($event, input.value)">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChangeSelectOption(myControl.value)">
      <mat-option *ngIf="loaders"><mat-progress-bar mode="buffer"></mat-progress-bar></mat-option>
      <mat-option *ngIf="showOptionAll" [value]="{id:'all',name: 'Todos'}">
        Todos
      </mat-option>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option[fieldName]}}
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="msgInfo" [class.color-txt]="isValid">{{msgInfo}}</mat-hint>
  </mat-form-field>

</form>
