import { Auditable } from '../@lib/models/commons.models';
import { IForm, IModel } from '../@lib/models/contracts.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export class TypeRtis extends Auditable implements IModel, IForm {
  id: string;
  created_at: Date;
  updated_at: Date;
  created_id: string;
  created_by: string;
  update_id: string;
  update_by: string;
  active = true;
  name: string;
  description: string;
  length: number;
  height: number;
  width: number;
  internal_dimension: string;
  external_dimension: string;
  usable_height: number;
  weight: number;
  closed_height: number;
  volume: number;
  stackable_cargo: number;
  default_pallet_id: string = null;
  default_pallet: TypeRtis;
  capacity: number;
  purchase_price: number;

  form: FormGroup;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.id = other.id;
      this.created_at = other.created_at;
      this.updated_at = other.updated_at;
      this.created_id = other.created_id;
      this.created_by = other.created_by;
      this.update_id = other.update_id;
      this.update_by = other.update_by;
      this.active = other.active;
      this.name = other.name;
      this.description = other.description;
      this.length = other.length;
      this.height = other.height;
      this.width = other.width;
      this.internal_dimension = other.internal_dimension;
      this.external_dimension = other.external_dimension;
      this.usable_height = other.usable_height;
      this.weight = other.weight;
      this.closed_height = other.closed_height;
      this.volume = other.volume;
      this.stackable_cargo = other.stackable_cargo;
      this.default_pallet_id = other.default_pallet_id;
      this.default_pallet = other.default_pallet;
      this.capacity = other.capacity;
      this.purchase_price = parseFloat(other.purchase_price);
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        id: new FormControl(this.id),
        active: new FormControl(this.active, [Validators.required]),
        name: new FormControl(this.name, [Validators.required]),
        description: new FormControl(this.description, [Validators.required]),
        length: new FormControl(this.length, [Validators.required]),
        height: new FormControl(this.height, [Validators.required]),
        width: new FormControl(this.width, [Validators.required]),
        internal_dimension: new FormControl(this.internal_dimension, [Validators.required]),
        external_dimension: new FormControl(this.external_dimension, [Validators.required]),
        usable_height: new FormControl(this.usable_height, [Validators.required]),
        weight: new FormControl(this.weight, [Validators.required]),
        closed_height: new FormControl(this.closed_height, [Validators.required]),
        volume: new FormControl(this.volume, [Validators.required]),
        stackable_cargo: new FormControl(this.stackable_cargo, [Validators.required]),
        default_pallet_id: new FormControl(this.default_pallet_id),
        capacity: new FormControl(this.capacity, [Validators.required]),
        purchase_price: new FormControl(this.purchase_price, [Validators.required]),
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}

