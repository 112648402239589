import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { AuthService } from 'src/app/service/auth.service';
import { RightSidebarService } from 'src/app/service/rightsidebar.service';
import { LanguageService } from 'src/app/service/language.service';
import { environment } from 'src/environments/environment';
import { version } from 'package.json';
import {TranslateService} from '@ngx-translate/core';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    public authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService
  ) {  }
  environment = environment;
  version = version;
  selectedBgColor = 'white';
  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isDarkSidebar = false;
  listLang = [
    { text: 'Português - BR', flag: 'assets/images/flags/brazil.jpg', lang: 'pt' },
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' }
  ];
  notifications: any[] = [];

  // Modo DarkLighth
  isDarTheme = false;

  ngOnInit() {
    this.config = this.configService.configData;

    this.langStoreValue = this.translate.getBrowserLang();
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        switch (this.langStoreValue) {
          case 'pt':
            this.defaultFlag = 'assets/images/flags/brazil.jpg';
            break;
          case 'en':
            this.defaultFlag = 'assets/images/flags/us.jpg';
            break;
          case 'es':
            this.defaultFlag = 'assets/images/flags/spain.jpg';
            break;
          case 'de':
            this.defaultFlag = 'assets/images/flags/germany.jpg';
            break;
        }
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  ngAfterViewInit() {

    // set header color on startup
    if (localStorage.getItem('choose_skin')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_skin')
      );
      this.selectedBgColor = localStorage.getItem('choose_skin_active');
    } else {
      this.renderer.addClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
      this.selectedBgColor = this.config.layout.theme_color;
    }

    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }

  modeTheme(modeDefinition){

    if (modeDefinition === 'ligth'){
      this.renderer.removeClass(this.document.body, 'dark');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      this.renderer.removeClass(this.document.body, 'menu_dark');
      this.renderer.removeClass(this.document.body, 'logo-black');

      if (localStorage.getItem('choose_skin')) {
        this.renderer.removeClass(
          this.document.body,
          localStorage.getItem('choose_skin')
        );
      } else {
        this.renderer.removeClass(
          this.document.body,
          'theme-' + this.config.layout.theme_color
        );
      }

      this.renderer.addClass(this.document.body, 'light');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      this.renderer.addClass(this.document.body, 'menu_light');
      this.renderer.addClass(this.document.body, 'logo-white');
      this.renderer.addClass(this.document.body, 'theme-white');
      const theme = 'light';
      const menuOption = 'menu_light';
      this.selectedBgColor = 'white';
      this.isDarkSidebar = false;
      localStorage.setItem('choose_logoheader', 'logo-white');
      localStorage.setItem('choose_skin', 'theme-white');
      localStorage.setItem('theme', theme);
      localStorage.setItem('menuOption', menuOption);
    } else {
      this.renderer.removeClass(this.document.body, 'light');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      this.renderer.removeClass(this.document.body, 'menu_light');
      this.renderer.removeClass(this.document.body, 'logo-white');
      if (localStorage.getItem('choose_skin')) {
        this.renderer.removeClass(
          this.document.body,
          localStorage.getItem('choose_skin')
        );
      } else {
        this.renderer.removeClass(
          this.document.body,
          'theme-' + this.config.layout.theme_color
        );
      }
      this.renderer.addClass(this.document.body, 'dark');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      this.renderer.addClass(this.document.body, 'menu_dark');
      this.renderer.addClass(this.document.body, 'logo-black');
      this.renderer.addClass(this.document.body, 'theme-black');
      const theme = 'dark';
      const menuOption = 'menu_dark';
      this.selectedBgColor = 'black';
      this.isDarkSidebar = true;
      localStorage.setItem('choose_logoheader', 'logo-black');
      localStorage.setItem('choose_skin', 'theme-black');
      localStorage.setItem('theme', theme);
      localStorage.setItem('menuOption', menuOption);
    }
  }

  logout() {
    this.authService.logout().then((res) => {
     // if (!res.success) {
        this.router.navigate(['/authentication/signin']);
    //  }
    });
  }
}
