import { Injectable } from '@angular/core';
import {BaseProviderService} from './base-provider.service';
import {ILibFormService, ILibTableService} from '../@lib/services/contracts.services';
import {TypesRtiFilter, TypesRtiSearch} from '../models/filters.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FilterPagination, PaginatedList} from '../@lib/models/commons.models';
import {TypeRtis} from '../models/typeRtis.model';

@Injectable({
  providedIn: 'root'
})
export class TypeRtisService extends BaseProviderService implements ILibTableService<TypeRtis, TypesRtiFilter, TypesRtiSearch>, ILibFormService<TypeRtis> {
  protected module = 'rtitype';

  constructor(protected http: HttpClient) {
    super(http);
  }

  create(): TypeRtis {
    return new TypeRtis();
  }

  get(id: string): Observable<TypeRtis> {
    return this.http.get<TypeRtis>(this.getUrl(this.module, [id]))
      .pipe(map(x => new TypeRtis(x)));
  }

  list(): Observable<any> {
    return this.http.get(this.getUrl(this.module, ['all']))
      .pipe(map(d => new PaginatedList<TypeRtis>(TypeRtis, d)));
  }

  filter(filter: FilterPagination<TypesRtiFilter, TypesRtiSearch>): Observable<PaginatedList<TypeRtis>> {
    return this.http.post<PaginatedList<TypeRtis>>(this.getUrl(this.module, ['filter']), filter)
      .pipe(map(d => new PaginatedList<TypeRtis>(TypeRtis, d)));
  }

  update(model: TypeRtis): Observable<TypeRtis> {
    return this.http.patch<TypeRtis>(this.getUrl(this.module, [model.id]), model).pipe(map(x => new TypeRtis(x)));
  }

  save(model: TypeRtis): Observable<TypeRtis> {
    delete model.id;
    return this.http.post<TypeRtis>(this.getUrl(this.module), model).pipe(map(x => new TypeRtis(x)));
  }

  delete(id: number): Observable<TypeRtis> {
    return this.http.delete<TypeRtis>(this.getUrl(this.module, [id]))
      .pipe(map(x => new TypeRtis(x)));
  }

}
