import { DateUtils } from 'src/app/shared/utils';
import { Auditable } from '../@lib/models/commons.models';
import { IForm, IModel } from '../@lib/models/contracts.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RtiType } from './rti-type.model';
import { Places } from './places.model';

export class ReportExtractMovement implements IModel {

  id: string;
  year: number;
  month: string;
  date: string;
  guide_number: number;
  transaction: number;
  origin: string;
  destination: string;
  filterPart: string;
  counterPart: string;
  movementType: string;
  rtiType: string;
  nfeNumber: string;
  rtiStatus: string;
  quantity: number;
  movementStep: string;

  form: FormGroup;

  constructor(other?: any) {
    if (other) {
      this.id = other.id;
      this.year = other.year;
      this.month = other.month;
      this.date = other.date;
      this.guide_number = other.guide_number;
      this.transaction = other.transaction;
      this.origin = other.origin;
      this.destination = other.destination;
      this.filterPart = other.filterPart;
      this.counterPart = other.counterPart;
      this.movementType = other.movementType;
      this.rtiType = other.rtiType;
      this.nfeNumber = other.nfeNumber;
      this.rtiStatus = other.rtiStatus;
      this.quantity = other.quantity;
      this.movementStep = other.movementStep;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        id: new FormControl(this.id),
        quantity: new FormControl(this.quantity, [Validators.required]),
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }

  get report() {
    return {
      Ano: this.year,
      Mês: this.month,
      Data: DateUtils.toBRDateString(this.date),
      Guia: this.guide_number,
      Transação: this.transaction,
      'LOCAL Origem': this.origin,
      'LOCAL Destino': this.destination,
      'LOCAL Filtro': this.filterPart,
      'CONTRAPART.': this.counterPart,
      'Tipo Movimento': this.movementType,
      RTI: this.rtiType,
      'Nota Fiscal': this.nfeNumber,
      Estado: this.rtiStatus,
      'Quant.': this.quantity,
      'Etapa Atual': this.movementStep
    };
  }
}

