<h1 mat-dialog-title>NOTAFISCAL - XML</h1>
<div mat-dialog-content>
  <div>
    <table>
      <tbody>
      <tr>
        <td rowspan="4">
          <h2>
            <strong>{{resultData.nfeProc.NFe.infNFe.emit.xNome}}</strong>
          </h2>
          <span>
           <small>
              {{resultData.nfeProc.NFe.infNFe.emit.enderEmit.xLgr}}
             {{resultData.nfeProc.NFe.infNFe.emit.enderEmit.nro}} -
            CEP: {{resultData.nfeProc.NFe.infNFe.emit.enderEmit.CEP}} -
             {{resultData.nfeProc.NFe.infNFe.emit.enderEmit.xBairro}} -
             {{resultData.nfeProc.NFe.infNFe.emit.enderEmit.xMun}}/{{resultData.nfeProc.NFe.infNFe.emit.enderEmit.UF}} -
            Fone:{{resultData.nfeProc.NFe.infNFe.emit.enderEmit.fone}}
           </small>
          </span>
        </td>
        <td rowspan="4">
          <h2>DANFE</h2>
          <p>Documento Auxiliar da
            Nota Fiscal Eletrônica</p>
          <h2>
            Nº 7336 <br>
            SÉRIE 1 <br>
            FOLHA 01/01
          </h2>
        </td>
      </tr>
      <tr>
        <td>||||||||||||||||||| |||||||||||||||||||||</td>
      </tr>
      <tr>
        <td>
          <span>CHAVE DE ACESSO</span><br>
          <small>{{resultData.nfeProc.protNFe.infProt.chNFe}}</small>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            Consulta de autenticidade no portal nacional da NF-e
            www.nfe.fazenda.gov.br/portal
            ou no site da Sefaz Autorizadora
          </span>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <span>NATUREZA DA OPERAÇÃO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.ide.natOp}}</strong></span>
        </td>
        <td colspan="1">
          <span>PROTOCOLO DE AUTORIZAÇÃO DE USO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.ide.natOp}}</strong></span>
        </td>
      </tr>
      <tr>
        <td>
          <span>INSCRIÇÃO ESTADUAL</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.emit.IE}}</strong></span>
        </td>
        <td>
          <span>INSCRIÇÃO ESTADUAL DO SUBSTITUTO TRIBUTÁRIO</span><br>
          <span><strong>                         </strong></span>
        </td>
        <td>
          <span>CNPJ/CPF</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.emit.CNPJ}}</strong></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <br>
  <div>
    <span><strong>DESTINATÁRIO/REMETENTE</strong></span>
    <table class="w-100">
      <tbody>
      <tr>
        <td>
          <span>NOME/RAZÃO SOCIAL</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.xNome}}</strong></span>
        </td>
        <td>
          <span>CNPJ/CPF</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.CNPJ}}</strong></span>
        </td>
        <td>
          <span>INSCRIÇÃO ESTADUAL</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.IE}}</strong></span>
        </td>
        <td>
          <span>DATA EMISSÃO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.xNome}}</strong></span>
        </td>
      </tr>
      <tr>
        <td>
          <span>ENDEREÇO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.enderDest.xLgr}} {{resultData.nfeProc.NFe.infNFe.dest.enderDest.nro}}</strong></span>
        </td>
        <td>
          <span>BAIRRO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.enderDest.xBairro}}</strong></span>
        </td>
        <td>
          <span>CEP</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.enderDest.CEP}}</strong></span>
        </td>
        <td>
          <span>DATA SAÍDA</span><br>
          <span><strong>-</strong></span>
        </td>
      </tr>
      <tr>
        <td>
          <span>MUNICÍPIO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.enderDest.xMun}}</strong></span>
        </td>
        <td>
          <span>FONE/FAX</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>UF</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.dest.enderDest.UF}}</strong></span>
        </td>
        <td>
          <span>HORA SAÍDA</span><br>
          <span><strong>-</strong></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div>
    <span><strong>CÁLCULO DO IMPOSTO</strong></span>
    <table class="w-100">
      <tbody>
      <tr>
        <td>
          <span>BASE DE CÁLCULO DO ICMS</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vBC}}</strong></span>
        </td>
        <td>
          <span>VALOR DO ICMS</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vICMS}}</strong></span>
        </td>
        <td>
          <span>BASE DE CÁLC. DO ICMS SUBST</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vICMSDeson}}</strong></span>
        </td>
        <td>
          <span>VALOR DO ICMS SUBST.</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vICMSDeson}}</strong></span>
        </td>
        <td colspan="2">
          <span>VALOR TOTAL DOS PRODUTOS</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vProd}}</strong></span>
        </td>
      </tr>
      <tr>
        <td>
          <span>VALOR DO FRETE</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vFrete}}</strong></span>
        </td>
        <td>
          <span>VALOR DO SEGURO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vSeg}}</strong></span>
        </td>
        <td>
          <span>DESCONTO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vDesc}}</strong></span>
        </td>
        <td>
          <span>OUTRAS DESPESAS ACESSÓRIAS</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vII}}</strong></span>
        </td>
        <td>
          <span>VALOR DO IPI</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vIPI}}</strong></span>
        </td>
        <td>
          <span>VALOR TOTAL DA NOTA</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.total.ICMSTot.vNF}}</strong></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div>
    <span><strong>TRANSPORTADOR/VOLUMES TRANSPORTADOS</strong></span>
    <table class="w-100">
      <tbody>
      <tr>
        <td>
          <span>NOME/RAZÃO SOCIAL</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>FRETE POR CONTA <div class="type-transport"> {{resultData.nfeProc.NFe.infNFe.transp.modFrete}} </div></span><br>
          <span>
            <table style="border: none; text-align: center">
              <tbody>
              <tr>
                <td class="no-boder-td">0 - Emitente</td>
                <td class="no-boder-td">2 - Terceiros</td>
                <td class="no-boder-td">4 - Destinatário Próprio</td>
              </tr>
              <tr>
                <td class="no-boder-td">1 - Destinatário/Remetente</td>
                <td class="no-boder-td">3 - Emitente Próprio</td>
                <td class="no-boder-td">9 - Sem Frete</td>
              </tr>
              </tbody>
            </table>
          </span>
        </td>
        <td>
          <span>CÓDIGO ANTT</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>PLACA DO VEÍCULO</span><br>
          <span><strong> </strong></span>
        </td>
        <td colspan="2">
          <span>UF</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>CNPJ/CPF</span><br>
          <span><strong> </strong></span>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <span>ENDEREÇO</span><br>
          <span><strong> </strong></span>
        </td>
        <td colspan="3">
          <span>MUNICÍPIO</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>UF</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>INSCRIÇÃO ESTADUAL</span><br>
          <span><strong> </strong></span>
        </td>
      </tr>
      <tr>
        <td>
          <span>QUANTIDADE</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.transp.vol.qVol}}</strong></span>
        </td>
        <td colspan="2">
          <span>ESPÉCIE</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>MARCA</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>NUMERAÇÃO</span><br>
          <span><strong> </strong></span>
        </td>
        <td>
          <span>PESO BRUTO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.transp.vol.pesoB}}</strong></span>
        </td>
        <td>
          <span>PESO LÍQUIDO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.transp.vol.pesoL}}</strong></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div>
    <span><strong>DADOS DOS PRODUTOS/SERVIÇOS</strong></span>
    <table class="w-100">
      <tbody>
      <tr>
        <td>
          <span>CÓDIGO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.cProd}}</strong></span>
        </td>
        <td>
          <span>DESCRIÇÃO DO PRODUTO/SERVIÇO</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.xProd}}</strong></span>
        </td>
        <td>
          <span>NCM/SH</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.NCM}}</strong></span>
        </td>
        <td>
          <span>CST</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40.orig}}{{resultData.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40.CST}}</strong></span>
        </td>
        <td>
          <span>CFOP</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.CFOP}}</strong></span>
        </td>
        <td>
          <span>UN</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.uCom}}</strong></span>
        </td>
        <td>
          <span>QTDE</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.qCom}}</strong></span>
        </td>
        <td>
          <span>VALOR</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.vUnCom}}</strong></span>
        </td>
        <td>
          <span>VALOR TOTAL</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.det.prod.vProd}}</strong></span>
        </td>
        <td>
          <span>B. CÁLC. ICMS</span><br>
          <span><strong>0</strong></span>
        </td>
        <td>
          <span>VALOR ICMS</span><br>
          <span><strong>0</strong></span>
        </td>
        <td>
          <span>VALOR IPI</span><br>
          <span><strong>0</strong></span>
        </td>
        <td>
          <span>ALIQ. ICMS</span><br>
          <span><strong>0</strong></span>
        </td>
        <td>
          <span>ALIQ. IPI</span><br>
          <span><strong>0</strong></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <br><br><br>
  <div>
    <span><strong>CÁLCULO DO ISSQN</strong></span>
    <table class="w-100">
      <tbody>
      <tr>
        <td>
          <span>INSCRIÇÃO MUNICIPAL</span><br>
          <span><strong> </strong></span>
        </td>
        <td style="text-align: right">
          <span>VALOR TOTAL DOS SERVIÇOS</span><br>
          <span><strong>0</strong></span>
        </td>
        <td style="text-align: right">
          <span>BASE DE CÁLCULO DO ISSQN</span><br>
          <span><strong>0</strong></span>
        </td>
        <td style="text-align: right">
          <span>BASE DE CÁLCULO DO ISSQN</span><br>
          <span><strong>0</strong></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div>
    <span><strong>DADOS ADICIONAIS</strong></span>
    <table class="w-100" style="text-align: left !important;">
      <tbody>
      <tr>
        <td>
          <span>OBSERVAÇÕES</span><br>
          <span><strong>{{resultData.nfeProc.NFe.infNFe.infAdic.infCpl}}</strong></span>
        </td>
        <td>
          <span>RESERVADO AO FISCO</span><br>
          <span><strong>0</strong></span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
